/* eslint-disable no-throw-literal */

import axios from "axios";
import config from "./config";
import store from "../store";
import { uiStartLoading, uiStopLoading } from "../store/actions/loading";

const ReferenceAxios = axios.create({
	baseURL: config.DOCUMENT_SERVER,
	headers: {
		"Cross-Origin-Opener-Policy": "same-origin",
		"X-Frame-Options": "SAMEORIGIN",
		"X-Content-Type-Options": "nosniff",
	},
});

const { dispatch } = store;

ReferenceAxios.interceptors.request.use(
	(config) => {
		if (!navigator.onLine) {
			throw new Error("Please check your Internet Connection")
		}
		dispatch(uiStartLoading());
		return config;
	},
	(error) => {
		dispatch(uiStopLoading());
		return Promise.reject(error);
	}
);

ReferenceAxios.interceptors.response.use(
	(response) => {
		dispatch(uiStopLoading());
		return response;
	},
	async (err) => {
		dispatch(uiStopLoading());
		return Promise.reject(err);
	}
);
export default ReferenceAxios;
