const config = {
	"SERVER_URL": process.env.REACT_APP_SERVER_URL,
	"DOCUMENT_SERVER": process.env.REACT_APP_DOCUMENT_SERVER,
	"SAVE_CONTINUE_SERVER": process.env.REACT_APP_SAVE_CONTINUE_SERVER,
	"VERIFYME_PUBLIC_KEY": process.env.REACT_APP_VERIFYME_PUBLIC_KEY,
	"VERIFYME_PRODUCT_CODE": process.env.REACT_APP_VERIFYME_PRODUCT_CODE,
	"ADMIN_URL": process.env.REACT_APP_ADMIN_URL,
	"REFERENCE_URL": process.env.REACT_APP_REFERENCE_URL,
	"PaymentGatewayPublicKey": process.env.REACT_APP_PaymentGatewayPublicKey,
	"PaymentGatewayUrl": process.env.REACT_APP_PaymentGatewayUrl,
	"PaymentGatewayMerchantCode": process.env.REACT_APP_PaymentGatewayMerchantCode,
	"isProduction": process.env.REACT_APP_IS_PRODUCTION === "true" ? true : false,
	"EnterpriseBusinessRegAmount": process.env.REACT_APP_BUSINESS_REGISTRATION_Enterprise,
	"LimitedLiabilityCompanyBusinessRegAmount": process.env.REACT_APP_BUSINESS_REGISTRATION_LimitedLiabilityCompany,
	"IS_CLOUD": process.env.REACT_APP_IS_CLOUD === "true" ? true : false,
	"subscription_key_name": process.env.REACT_APP_subscription_key_name,
	"subscription_key_value": process.env.REACT_APP_subscription_key_value,
	"x_correlation_id": process.env.REACT_APP_x_correlation_id,
	"xtoken_password": process.env.REACT_APP_xtoken_password,
	"client_id": process.env.REACT_APP_CLIENT_ID, 
	"product_id": process.env.REACT_APP_PRODUCT_ID,
	numberOfShares: Number(process.env.REACT_APP_NUMBER_OF_SHARES)
}

export default config
