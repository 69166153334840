import { lazy, useEffect } from "react";
import { Suspense } from "react";
import LoadingIndicator from "./components/common/LoadingIndicator";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { clickjackingFix } from "./store/actions";
import Privacy from "./components/common/Privacy";
import { BrowserRouter } from "react-router-dom";
const Routes = lazy(() => import("./routes"));

function App() {
  const { loading: { isLoading } } = useSelector((store: RootState) => store)

  useEffect(() => {
    clickjackingFix()
  }, [])


  return (
    <Suspense fallback={<LoadingIndicator />}>
      {isLoading && <LoadingIndicator />}
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <ToastContainer />
      <Privacy />
    </Suspense>

  );
}

export default App;
