import { BusinessCategory, DropOffReason, ShareholderTypeEnum } from "../enums";

export const businessCategories = [
	{
		key: "Sole Proprietorship",
		value: "Sole Proprietorship",
		code: "ENACC",
		files: [
			{
				label: "Completed Account Package",
				formLabel: "completedAccountPackage",
				value: "CAP",
				show: false,
			},
			{
				label: "Tax Identification Number",
				formLabel: "taxIdentificationNumber",
				value: "TIN",
				show: false,
			},
			{
				label: "Valid ID of all Signatories",
				formLabel: "validIdOfAllSignatories",
				value: "VALID ID",
				show: false,
			},
			{
				label: "SIGNATURE CARDS",
				formLabel: "signatureOfSignatories",
				value: "SIGNATURE CARDS",
				show: false,
			},
			{
				label: "Valid ID of Directors",
				formLabel: "validIdOfDirectors",
				value: "VALID DIREC",
				show: false,
			},
			{
				label: "Executed and sealed Board Resolution",
				formLabel: "boardResolution",
				value: "EBR",
				show: true,
				required: true,
			},
			{
				label: "Reference 1",
				formLabel: "firstReferenceForCompanies",
				value: "REF1",
				show: true,
			},
			{
				label: "Reference 2",
				formLabel: "secondReferenceForCompanies",
				value: "REF2",
				show: true,
			},
			{
				label: "Scuml",
				formLabel: "scumlCertificate",
				value: "SCUML",
				show: true,
			},
			{
				label: "Certificate of Registration of Business name",
				formLabel: "certificateOfRegistration",
				value: "CRB",
				show: true,
			},
		],
	},
	{
		key: "Limited Liability Company",
		value: "Limited Liability Company",
		code: "CORACC",
		files: [
			{
				label: "Completed Account Package",
				formLabel: "completedAccountPackage",
				value: "CAP",
				show: false,
			},
			{
				label: "CTC of Form CO7",
				formLabel: "formOfCO7",
				value: "CO7",
				show: true,
			},
			{
				label: "CTC of Form CO2",
				formLabel: "formOfCAC23",
				value: "CO2",
				show: true,
			},

			{
				label: "Tax Identification Number",
				formLabel: "taxIdentificationNumber",
				value: "TIN",
				show: false,
			},
			{
				label: "Valid ID of all Signatories",
				formLabel: "validIdOfAllSignatories",
				value: "VALID ID",
				show: false,
			},
			{
				label: "SIGNATURE CARDS",
				formLabel: "signatureOfSignatories",
				value: "SIGNATURE CARDS",
				show: false,
			},
			{
				label: "Valid ID of Directors",
				formLabel: "validIdOfDirectors",
				value: "VALID DIREC",
				show: false,
			},
			{
				label: "Executed and sealed Board Resolution",
				formLabel: "boardResolution",
				value: "EBR",
				show: true,
			},
			{
				label: "Reference 1",
				formLabel: "firstReferenceForCompanies",
				value: "REF1",
				show: true,
			},
			{
				label: "Reference 2",
				formLabel: "secondReferenceForCompanies",
				value: "REF2",
				show: true,
			},
			{
				label: "Scuml",
				formLabel: "scumlCertificate",
				value: "SCUML",
				show: true,
			},
		],
	},
	{
		key: "NGO, Association & Cooperative",
		value: "NGO, Association & Cooperative",
		code: "NMA",
		files: [
			{
				label: "Completed Account Package",
				formLabel: "completedAccountPackage",
				value: "CAP",
				show: false,
			},
			{
				label: "Tax Identification Number",
				formLabel: "taxIdentificationNumber",
				value: "TIN",
				show: false,
			},
			{
				label: "Valid ID of all Signatories",
				formLabel: "validIdOfAllSignatories",
				value: "VALID ID",
				show: false,
			},
			{
				label: "Passport Photographs of all signatories",
				formLabel: "PPS",
				value: "PPS",
				show: false,
			},
			{
				label: "Reference 1",
				formLabel: "firstReferenceForCompanies",
				value: "REF1",
				show: true,
			},
			{
				label: "Reference 2",
				formLabel: "secondReferenceForCompanies",
				value: "REF2",
				show: true,
			},
			{
				label: "Constitutions,Rules,Regulations and By-laws",
				formLabel: "constitutionRulesAndRegulations",
				value: "CRRB",
				show: true,
			},
		],
	},
];

export const businessSubCategories = [
	{ key: "Sole Proprietorship", value: "Sole Proprietorship" },
	{ key: "Limited Liability Company", value: "Limited Liability Company" },
	{
		key: "NGO, Association & Cooperative",
		value: "NGO, Association & Cooperative",
	},
];

export const businessSubSegements = [
	{
		label: "NGOs & Associations",
		value: "FXXXA",
		legalEntity: "NGO",
	},
	{
		label: "Contracting",
		value: "FXXXB",
		legalEntity: "IMPORTER AND EXPORTER",
	},
	{
		label: "Financial Institutions",
		value: "FXXXC",
		legalEntity: "FIN",
	},
	{
		label: "Health Care",
		value: "FXXXD",
		legalEntity: "HEA",
	},
	{
		label: "Hospitality",
		value: "FXXXE",
		legalEntity: "NFI",
	},
	{ label: "Manufacturing", value: "FXXXF", legalEntity: "PRIV" },
	{
		label: "Professional Firms",
		value: "FXXXG",
		legalEntity: "PRIV",
	},
	{
		label: "Religious Bodies",
		value: "FXXXH",
		legalEntity: "REL",
	},
	{ label: "Retail Trade", value: "FXXXI", legalEntity: "Private Company" },
	{
		label: "Schools",
		value: "FXXXJ",
		legalEntity: "EDU",
	},
	{ label: "Agribusiness", value: "FXXXK", legalEntity: "AGRIC" },
	{
		label: "Wholesale Trade",
		value: "FXXXL",
		legalEntity: "IMPORTER AND EXPORTER",
	},
	{
		label: "Technology",
		value: "FXXXN",
		legalEntity: "INFO",
	},
	{
		label: "Renewable Energy",
		value: "FXXXO",
		legalEntity: "PRIV",
	},
	{
		label: "Entertainment & Fashion",
		value: "FXXXP",
		legalEntity: "PRIV",
	},
	{ label: "Others", value: "FXXXM", legalEntity: "OTCY" },
];

export const businessSectors = [];

export const currencyTypes = [
	{ label: "USD ($)", value: "USD" },
	{ label: "EUR (€)", value: "EUR" },
	{ label: "GBP (£)", value: "GBP" },
];

export const IDs = [
	{ label: 'NIN', value: "1", code: 'D4' },
	{ label: "Driver's Licence", value: "2", code: "D2" },
	{ label: "Voter's Card", value: "3", code: "D3" },
	{ label: "International Passport", value: "4", code: "D1" },
];

export const IDs_Individual = [
	"Voter's Card",
	"NIN",
	"Driver Licence",
	"International Passport",
];
export const IDs_Corporate = [
	{ label: "RC/BN", value: "5", code: "" },
	{ label: "TIN", value: "4", code: "" },
];

export const stateLga = [
	{
		state: "Adamawa",
		alias: "adamawa",
		lgas: [
			"Demsa",
			"Fufure",
			"Ganye",
			"Gayuk",
			"Gombi",
			"Grie",
			"Hong",
			"Jada",
			"Larmurde",
			"Madagali",
			"Maiha",
			"Mayo Belwa",
			"Michika",
			"Mubi North",
			"Mubi South",
			"Numan",
			"Shelleng",
			"Song",
			"Toungo",
			"Yola North",
			"Yola South",
		],
		branches: [
			{
				branch: "YOLA BRANCH",
				sol: "093",
			},
		],
	},
	{
		state: "Akwa Ibom",
		alias: "akwa_ibom",
		lgas: [
			"Abak",
			"Eastern Obolo",
			"Eket",
			"Esit Eket",
			"Essien Udim",
			"Etim Ekpo",
			"Etinan",
			"Ibeno",
			"Ibesikpo Asutan",
			"Ibiono-Ibom",
			"Ikot Abasi",
			"Ika",
			"Ikono",
			"Ikot Ekpene",
			"Ini",
			"Mkpat-Enin",
			"Itu",
			"Mbo",
			"Nsit-Atai",
			"Nsit-Ibom",
			"Nsit-Ubium",
			"Obot Akara",
			"Okobo",
			"Onna",
			"Oron",
			"Udung-Uko",
			"Ukanafun",
			"Oruk Anam",
			"Uruan",
			"Urue-Offong/Oruko",
			"Uyo",
		],
		branches: [
			{
				branch: "UYO ABAK ROAD BRANCH",
				sol: "028",
			},
			{
				branch: "EKET BRANCH",
				sol: "040",
			},
			{
				branch: "UYO, ORON BRANCH",
				sol: "042",
			},
			{
				branch: "IKOT EKPENE I BRANCH",
				sol: "063",
			},
		],
	},
	{
		state: "Anambra",
		alias: "anambra",
		lgas: [
			"Aguata",
			"Anambra East",
			"Anaocha",
			"Awka North",
			"Anambra West",
			"Awka South",
			"Ayamelum",
			"Dunukofia",
			"Ekwusigo",
			"Idemili North",
			"Idemili South",
			"Ihiala",
			"Njikoka",
			"Nnewi North",
			"Nnewi South",
			"Ogbaru",
			"Onitsha North",
			"Onitsha South",
			"Orumba North",
			"Orumba South",
			"Oyi",
		],
		branches: [
			{
				branch: "ONITSHA NEW MKT RD I BRANCH",
				sol: "021",
			},
			{
				branch: "OKO BRANCH",
				sol: "067",
			},
			{
				branch: "AWKA BRANCH",
				sol: "094",
			},
			{
				branch: "NNEWI I BRANCH",
				sol: "131",
			},
			{
				branch: "ONITSHA BRIDGEHEAD BRANCH",
				sol: "140",
			},
			{
				branch: "AWKA II BRANCH",
				sol: "243",
			},
			{
				branch: "ONITSHA NEW MARKET RD III BRANCH",
				sol: "248",
			},
			{
				branch: "OBOSI BRANCH",
				sol: "270",
			},
		],
	},
	{
		state: "Ogun",
		alias: "ogun",
		lgas: [
			"Abeokuta North",
			"Abeokuta South",
			"Ado-Odo/Ota",
			"Egbado North",
			"Ewekoro",
			"Egbado South",
			"Ijebu North",
			"Ijebu East",
			"Ifo",
			"Ijebu Ode",
			"Ijebu North East",
			"Imeko Afon",
			"Ikenne",
			"Ipokia",
			"Odeda",
			"Obafemi Owode",
			"Odogbolu",
			"Remo North",
			"Ogun Waterside",
			"Shagamu",
		],
		branches: [
			"IJEBU-ODE I BRANCH",
			"ABEOKUTA BRANCH",
			"AGO-IWOYE",
			"SAGAMU BRANCH",
			"OTTA BRANCH",
			"AKUTE BRANCH",
			"AGBARA BRANCH",
			"IJEBU-ODE II BRANCH",
			"RCCG CAMP BRANCH",
		],
	},
	{
		state: "Ondo",
		alias: "ondo",
		branches: [
			{
				branch: "ONDO BRANCH",
				sol: "012",
			},
			{
				branch: "IGBOKODA BRANCH",
				sol: "100",
			},
			{
				branch: "AKURE 1 BRANCH",
				sol: "133",
			},
			{
				branch: "AKURE II BRANCH",
				sol: "279",
			},
		],
		lgas: [
			"Akoko North-East",
			"Akoko North-West",
			"Akoko South-West",
			"Akoko South-East",
			"Akure North",
			"Akure South",
			"Ese Odo",
			"Idanre",
			"Ifedore",
			"Ilaje",
			"Ile Oluji-Okeigbo",
			"Irele",
			"Odigbo",
			"Okitipupa",
			"Ondo East",
			"Ondo West",
			"Ose",
			"Owo"]
	},
	{
		state: "Rivers",
		alias: "rivers",
		lgas: [
			"Abua/Odual",
			"Ahoada East",
			"Ahoada West",
			"Andoni",
			"Akuku-Toru",
			"Asari-Toru",
			"Bonny",
			"Degema",
			"Emuoha",
			"Eleme",
			"Ikwerre",
			"Etche",
			"Gokana",
			"Khana",
			"Obio/Akpor",
			"Ogba/Egbema/Ndoni",
			"Ogu/Bolo",
			"Okrika",
			"Omuma",
			"Opobo/Nkoro",
			"Oyigbo",
			"Port Harcourt",
			"Tai",
		],
		branches: [
			{
				branch: "PH MAIN BRANCH",
				sol: "003",
			},
			{
				branch: "GARRISON BRANCH",
				sol: "043",
			},
			{
				branch: "OLU OBASANJO BRANCH",
				sol: "049",
			},
			{
				branch: "IKWERRE I BRANCH",
				sol: "064",
			},
			{
				branch: "OYIGBO BRANCH",
				sol: "108",
			},
			{
				branch: "ABULOMA BRANCH",
				sol: "109",
			},
			{
				branch: "AGGREY BRANCH",
				sol: "126",
			},
			{
				branch: "TRANS AMADI II BRANCH",
				sol: "134",
			},
			{
				branch: "IKWERRE II BRANCH",
				sol: "239",
			},
			{
				branch: "AZIKIWE BRANCH",
				sol: "252",
			},
			{
				branch: "RUMUOMASI BRANCH",
				sol: "253",
			},
			{
				branch: "PH ABA ROAD 2",
				sol: "256",
			},
			{
				branch: "BORI BRANCH",
				sol: "261",
			},
			{
				branch: "TRANS AMADI III BRANCH",
				sol: "268",
			},
			{
				branch: "RUMUOKORO BRANCH",
				sol: "275",
			},
		],
	},
	{
		state: "Bauchi",
		alias: "bauchi",
		lgas: [
			"Alkaleri",
			"Bauchi",
			"Bogoro",
			"Damban",
			"Darazo",
			"Dass",
			"Gamawa",
			"Ganjuwa",
			"Giade",
			"Itas/Gadau",
			"Jama'are",
			"Katagum",
			"Kirfi",
			"Misau",
			"Ningi",
			"Shira",
			"Tafawa Balewa",
			"Toro",
			"Warji",
			"Zaki",
		],
		branches: [
			{
				branch: "COMMERCIAL RD BAUCHI BRANCH",
				sol: "097",
			},
			{
				branch: "AZARE BRANCH",
				sol: "191",
			},
			{
				branch: "BAUCHI JOS ROAD",
				sol: "192",
			},
		],
	},
	{
		state: "Benue",
		alias: "benue",
		lgas: [
			"Agatu",
			"Apa",
			"Ado",
			"Buruku",
			"Gboko",
			"Guma",
			"Gwer East",
			"Gwer West",
			"Katsina-Ala",
			"Konshisha",
			"Kwande",
			"Logo",
			"Makurdi",
			"Obi",
			"Ogbadibo",
			"Ohimini",
			"Oju",
			"Okpokwu",
			"Oturkpo",
			"Tarka",
			"Ukum",
			"Ushongo",
			"Vandeikya",
		],
		branches: [
			{
				branch: "MAKURDI BRANCH",
				sol: "035",
			},
		],
	},
	{
		state: "Borno",
		alias: "borno",
		lgas: [
			"Abadam",
			"Askira/Uba",
			"Bama",
			"Bayo",
			"Biu",
			"Chibok",
			"Damboa",
			"Dikwa",
			"Guzamala",
			"Gubio",
			"Hawul",
			"Gwoza",
			"Jere",
			"Kaga",
			"Kala/Balge",
			"Konduga",
			"Kukawa",
			"Kwaya Kusar",
			"Mafa",
			"Magumeri",
			"Maiduguri",
			"Mobbar",
			"Marte",
			"Monguno",
			"Ngala",
			"Nganzai",
			"Shani",
		],
		branches: [
			{
				branch: "MAIDUGURI I BRANCH",
				sol: "080",
			},
		],
	},
	{
		state: "Bayelsa",
		alias: "bayelsa",
		lgas: [
			"Brass",
			"Ekeremor",
			"Kolokuma/Opokuma",
			"Nembe",
			"Ogbia",
			"Sagbama",
			"Southern Ijaw",
			"Yenagoa",
		],
		branches: [
			{
				branch: "YENAGOA  BRANCH",
				sol: "047",
			},
			{
				branch: "YENAGOA II BRANCH",
				sol: "240",
			},
		],
	},
	{
		state: "Cross River",
		alias: "cross_river",
		lgas: [
			"Abi",
			"Akamkpa",
			"Akpabuyo",
			"Bakassi",
			"Bekwarra",
			"Biase",
			"Boki",
			"Calabar Municipal",
			"Calabar South",
			"Etung",
			"Ikom",
			"Obanliku",
			"Obubra",
			"Obudu",
			"Odukpani",
			"Ogoja",
			"Yakuur",
			"Yala",
		],
		branches: [
			{
				branch: "CALABAR BRANCH",
				sol: "038",
			},
			{
				branch: "IKOM BRANCH",
				sol: "060",
			},
			{
				branch: "NEW SECRETARIAT, CALABAR  BRANCH",
				sol: "235",
			},
		],
	},
	{
		state: "Delta",
		alias: "delta",
		lgas: [
			"Aniocha North",
			"Aniocha South",
			"Bomadi",
			"Burutu",
			"Ethiope West",
			"Ethiope East",
			"Ika North East",
			"Ika South",
			"Isoko North",
			"Isoko South",
			"Ndokwa East",
			"Ndokwa West",
			"Okpe",
			"Oshimili North",
			"Oshimili South",
			"Patani",
			"Sapele",
			"Udu",
			"Ughelli North",
			"Ukwuani",
			"Ughelli South",
			"Uvwie",
			"Warri North",
			"Warri South",
			"Warri South West",
		],
		branches: [
			{
				branch: "WARRI  I BRANCH",
				sol: "008",
			},
			{
				branch: "ASABA I BRANCH",
				sol: "054",
			},
			{
				branch: "EFFURUN BRANCH",
				sol: "072",
			},
			{
				branch: "WARRI II (AIRPORT RD) BRANCH",
				sol: "234",
			},
			{
				branch: "ASABA NNEBISI ROAD BRANCH",
				sol: "238",
			},
			{
				branch: "UGHELLI BRANCH",
				sol: "257",
			},
		],
	},
	{
		state: "Ebonyi",
		alias: "ebonyi",
		lgas: [
			"Abakaliki",
			"Afikpo North",
			"Ebonyi",
			"Afikpo South",
			"Ezza North",
			"Ikwo",
			"Ezza South",
			"Ivo",
			"Ishielu",
			"Izzi",
			"Ohaozara",
			"Ohaukwu",
			"Onicha",
		],
		branches: [
			{
				branch: "ABAKALIKI BRANCH",
				sol: "112",
			},
		],
	},
	{
		state: "Edo",
		alias: "edo",
		lgas: [
			"Akoko-Edo",
			"Egor",
			"Esan Central",
			"Esan North-East",
			"Esan South-East",
			"Esan West",
			"Etsako Central",
			"Etsako East",
			"Etsako West",
			"Igueben",
			"Ikpoba Okha",
			"Orhionmwon",
			"Oredo",
			"Ovia North-East",
			"Ovia South-West",
			"Owan East",
			"Owan West",
			"Uhunmwonde",
		],
		branches: [
			{
				branch: "MISSION ROAD BRANCH",
				sol: "009",
			},
			{
				branch: "UGBOWO BRANCH",
				sol: "086",
			},
		],
	},
	{
		state: "Ekiti",
		alias: "ekiti",
		lgas: [
			"Ado Ekiti",
			"Efon",
			"Ekiti East",
			"Ekiti South-West",
			"Ekiti West",
			"Emure",
			"Gbonyin",
			"Ido Osi",
			"Ijero",
			"Ikere",
			"Ilejemeje",
			"Irepodun/Ifelodun",
			"Ikole",
			"Ise/Orun",
			"Moba",
			"Oye",
		],
		branches: [
			{
				branch: "ADO-EKITI BRANCH",
				sol: "050",
			},
		],
	},
	{
		state: "Enugu",
		alias: "enugu",
		lgas: [
			"Awgu",
			"Aninri",
			"Enugu East",
			"Enugu North",
			"Ezeagu",
			"Enugu South",
			"Igbo Etiti",
			"Igbo Eze North",
			"Igbo Eze South",
			"Isi Uzo",
			"Nkanu East",
			"Nkanu West",
			"Nsukka",
			"Udenu",
			"Oji River",
			"Uzo Uwani",
			"Udi",
		],
		branches: [
			{
				branch: "ENUGU MKT RD BRANCH",
				sol: "057",
			},
			{
				branch: "GARDEN AVENUE ENUGU BRANCH",
				sol: "084",
			},
			{
				branch: "NSUKKA BRANCH",
				sol: "263",
			},
			{
				branch: "AGBANI TOWN BRANCH",
				sol: "265",
			},
			{
				branch: "PRESIDENTIAL ROAD BRANCH",
				sol: "278",
			},
		],
	},
	{
		state: "Federal Capital Territory",
		alias: "abuja",
		lgas: [
			"Abaji",
			"Bwari",
			"Gwagwalada",
			"Kuje",
			"Kwali",
			"Municipal Area Council",
		],
		branches: [
			{
				branch: "ABUJA FIRST CITY PLAZA BRANCH",
				sol: "002",
			},
			{
				branch: "ABUJA AREA 8 BRANCH",
				sol: "044",
			},
			{
				branch: "ABUJA FEDSEC PHASE III BRANCH",
				sol: "073",
			},
			{
				branch: "ABUJA WUSE II BRANCH",
				sol: "111",
			},
			{
				branch: "ABUJA BANEX PLAZA BRANCH",
				sol: "138",
			},
			{
				branch: "ASOKORO BRANCH",
				sol: "182",
			},
			{
				branch: "ABUJA AREA 7 BRANCH",
				sol: "185",
			},
			{
				branch: "ABUJA  ZONE 4 BRANCH",
				sol: "189",
			},
			{
				branch: "ABUJA GARKI II BRANCH",
				sol: "190",
			},
			{
				branch: "ABUJA PORTHACOURT CRESCENT BRANCH",
				sol: "205",
			},
			{
				branch: "JABI BRANCH",
				sol: "206",
			},
			{
				branch: "MAITAMA MEDITERRANEAN BRANCH",
				sol: "207",
			},
			{
				branch: "ABUJA BWARI BRANCH",
				sol: "218",
			},
			{
				branch: "ABUJA AMINU KANO CRESCENT BRANCH",
				sol: "220",
			},
			{
				branch: "ABUJA GUDU BRANCH",
				sol: "222",
			},
			{
				branch: "ABUJA NASS I BRANCH",
				sol: "223",
			},
			{
				branch: "GWAGWALADA BRANCH",
				sol: "225",
			},
			{
				branch: "ABUJA KUJE BRANCH",
				sol: "226",
			},
			{
				branch: "ABUJA CREST PLAZA BRANCH",
				sol: "227",
			},
			{
				branch: "ABUJA KUBWA BRANCH",
				sol: "231",
			},
			{
				branch: "ABUJA GANA STREET BRANCH",
				sol: "233",
			},
		],
	},
	{
		state: "Gombe",
		alias: "gombe",
		lgas: [
			"Akko",
			"Balanga",
			"Billiri",
			"Dukku",
			"Funakaye",
			"Gombe",
			"Kaltungo",
			"Kwami",
			"Nafada",
			"Shongom",
			"Yamaltu/Deba",
		],
		branches: [
			{
				branch: "GOMBE 1 BRANCH",
				sol: "092",
			},
			{
				branch: "ASHAKA BRANCH",
				sol: "130",
			},
		],
	},

	{
		state: "Jigawa",
		alias: "jigawa",
		lgas: [
			"Auyo",
			"Babura",
			"Buji",
			"Biriniwa",
			"Birnin Kudu",
			"Dutse",
			"Gagarawa",
			"Garki",
			"Gumel",
			"Guri",
			"Gwaram",
			"Gwiwa",
			"Hadejia",
			"Jahun",
			"Kafin Hausa",
			"Kazaure",
			"Kiri Kasama",
			"Kiyawa",
			"Kaugama",
			"Maigatari",
			"Malam Madori",
			"Miga",
			"Sule Tankarkar",
			"Roni",
			"Ringim",
			"Yankwashi",
			"Taura",
		],
		branches: ["DUTSE 1 BRANCH"],
	},
	{
		state: "Oyo",
		alias: "oyo",
		lgas: [
			"Afijio",
			"Akinyele",
			"Atiba",
			"Atisbo",
			"Egbeda",
			"Ibadan North",
			"Ibadan North-East",
			"Ibadan North-West",
			"Ibadan South-East",
			"Ibarapa Central",
			"Ibadan South-West",
			"Ibarapa East",
			"Ido",
			"Ibarapa North",
			"Irepo",
			"Iseyin",
			"Itesiwaju",
			"Iwajowa",
			"Kajola",
			"Lagelu",
			"Ogbomosho North",
			"Ogbomosho South",
			"Ogo Oluwa",
			"Olorunsogo",
			"Oluyole",
			"Ona Ara",
			"Orelope",
			"Ori Ire",
			"Oyo",
			"Oyo East",
			"Saki East",
			"Saki West",
			"Surulere Oyo State",
		],
		branches: [
			{
				branch: "DUGBE BRANCH",
				sol: "029",
			},
			{
				branch: "BODIJA BRANCH",
				sol: "058",
			},
			{
				branch: "UCH BRANCH",
				sol: "077",
			},
			{
				branch: "OJOO BRANCH",
				sol: "122",
			},
			{
				branch: "MOLETE BRANCH",
				sol: "148",
			},
			{
				branch: "AGBENI BRANCH",
				sol: "171",
			},
			{
				branch: "AGBOWO BRANCH",
				sol: "172",
			},
			{
				branch: "IWO ROAD BRANCH",
				sol: "177",
			},
		],
	},
	{
		state: "Imo",
		alias: "imo",
		lgas: [
			"Aboh Mbaise",
			"Ahiazu Mbaise",
			"Ehime Mbano",
			"Ezinihitte",
			"Ideato North",
			"Ideato South",
			"Ihitte/Uboma",
			"Ikeduru",
			"Isiala Mbano",
			"Mbaitoli",
			"Isu",
			"Ngor Okpala",
			"Njaba",
			"Nkwerre",
			"Nwangele",
			"Obowo",
			"Oguta",
			"Ohaji/Egbema",
			"Okigwe",
			"Orlu",
			"Orsu",
			"Oru East",
			"Oru West",
			"Owerri Municipal",
			"Owerri North",
			"Unuimo",
			"Owerri West",
		],
		branches: [
			{
				branch: "WETHERAL ROAD I BRANCH",
				sol: "085",
			},
			{
				branch: "WETHERAL ROAD II BRANCH",
				sol: "241",
			},
			{
				branch: "ORLU II BRANCH",
				sol: "244",
			},
			{
				branch: "MBAISE RD OWERRI BRANCH",
				sol: "251",
			},
		],
	},
	{
		state: "Kaduna",
		alias: "kaduna",
		lgas: [
			"Birnin Gwari",
			"Chikun",
			"Giwa",
			"Ikara",
			"Igabi",
			"Jaba",
			"Jema'a",
			"Kachia",
			"Kaduna North",
			"Kaduna South",
			"Kagarko",
			"Kajuru",
			"Kaura",
			"Kauru",
			"Kubau",
			"Kudan",
			"Lere",
			"Makarfi",
			"Sabon Gari",
			"Sanga",
			"Soba",
			"Zangon Kataf",
			"Zaria",
		],
		branches: [
			{
				branch: "AHMADU BELLO WAY KADUNA BRANCH",
				sol: "048",
			},
			{
				branch: "KACHIA ROAD KADUNA BRANCH",
				sol: "089",
			},
			{
				branch: "ZARIA 1 BRANCH",
				sol: "128",
			},
			{
				branch: "KANO ROAD KADUNA BRANCH",
				sol: "184",
			},
			{
				branch: "SABON TASHA BRANCH",
				sol: "197",
			},
			{
				branch: "ALI AKILU ROAD KADUNA BRANCH",
				sol: "199",
			},
			{
				branch: "KACHIA POLICE BRANCH BRANCH",
				sol: "209",
			},
			{
				branch: "YAKUBU GOWON WAY KADUNA BRANCH",
				sol: "210",
			},
		],
	},
	{
		state: "Kebbi",
		alias: "kebbi",
		lgas: [
			"Aleiro",
			"Argungu",
			"Arewa Dandi",
			"Augie",
			"Bagudo",
			"Birnin Kebbi",
			"Bunza",
			"Dandi",
			"Fakai",
			"Gwandu",
			"Jega",
			"Kalgo",
			"Koko/Besse",
			"Maiyama",
			"Ngaski",
			"Shanga",
			"Suru",
			"Sakaba",
			"Wasagu/Danko",
			"Yauri",
			"Zuru",
		],
		branches: [
			{
				branch: "KEBBI II BRANCH",
				sol: "201",
			},
		],
	},
	{
		state: "Kano",
		alias: "kano",
		lgas: [
			"Ajingi",
			"Albasu",
			"Bagwai",
			"Bebeji",
			"Bichi",
			"Bunkure",
			"Dala",
			"Dambatta",
			"Dawakin Kudu",
			"Dawakin Tofa",
			"Doguwa",
			"Fagge",
			"Gabasawa",
			"Garko",
			"Garun Mallam",
			"Gezawa",
			"Gaya",
			"Gwale",
			"Gwarzo",
			"Kabo",
			"Kano Municipal",
			"Karaye",
			"Kibiya",
			"Kiru",
			"Kumbotso",
			"Kunchi",
			"Kura",
			"Madobi",
			"Makoda",
			"Minjibir",
			"Nasarawa",
			"Rano",
			"Rimin Gado",
			"Rogo",
			"Shanono",
			"Takai",
			"Sumaila",
			"Tarauni",
			"Tofa",
			"Tsanyawa",
			"Tudun Wada",
			"Ungogo",
			"Warawa",
			"Wudil",
		],
		branches: [
			{
				branch: "KANO MAIN BRANCH",
				sol: "004",
			},
			{
				branch: "KANO BELLO ROAD BRANCH",
				sol: "055",
			},
			{
				branch: "KANO MM WAY 1 BRANCH",
				sol: "183",
			},
			{
				branch: "KANO 40 MM WAY  BRANCH",
				sol: "199",
			},
			{
				branch: "KANO IBRAHIM TAIWO BRANCH",
				sol: "200",
			},
			{
				branch: "KANO BOMPAI BRANCH",
				sol: "213",
			},
		],
	},
	{
		state: "Kogi",
		alias: "kogi",
		lgas: [
			"Ajaokuta",
			"Adavi",
			"Ankpa",
			"Bassa",
			"Dekina",
			"Ibaji",
			"Idah",
			"Igalamela Odolu",
			"Ijumu",
			"Kogi",
			"Kabba/Bunu",
			"Lokoja",
			"Ofu",
			"Mopa Muro",
			"Ogori/Magongo",
			"Okehi",
			"Okene",
			"Olamaboro",
			"Omala",
			"Yagba East",
			"Yagba West",
		],
		branches: [
			{
				branch: "LOKOJA 1 BRANCH",
				sol: "102",
			},
			{
				branch: "AYINGBA BRANCH",
				sol: "224",
			},
		],
	},
	{
		state: "Osun",
		alias: "osun",
		lgas: [
			"Aiyedire",
			"Atakunmosa West",
			"Atakunmosa East",
			"Aiyedaade",
			"Boluwaduro",
			"Boripe",
			"Ife East",
			"Ede South",
			"Ife North",
			"Ede North",
			"Ife South",
			"Ejigbo",
			"Ife Central",
			"Ifedayo",
			"Egbedore",
			"Ila",
			"Ifelodun",
			"Ilesa East",
			"Ilesa West",
			"Irepodun",
			"Irewole",
			"Isokan",
			"Iwo",
			"Obokun",
			"Odo Otin",
			"Ola Oluwa",
			"Olorunda",
			"Oriade",
			"Orolu",
			"Osogbo",
		],
		branches: [
			{
				branch: "OSOGBO I BRANCH",
				sol: "068",
			},
			{
				branch: "ILESHA BRANCH",
				sol: "105",
			},
			{
				branch: "ILE- IFE BRANCH",
				sol: "286",
			},
		],
	},
	{
		state: "Sokoto",
		alias: "sokoto",
		lgas: [
			"Gudu",
			"Gwadabawa",
			"Illela",
			"Isa",
			"Kebbe",
			"Kware",
			"Rabah",
			"Sabon Birni",
			"Shagari",
			"Silame",
			"Sokoto North",
			"Sokoto South",
			"Tambuwal",
			"Tangaza",
			"Tureta",
			"Wamako",
			"Wurno",
			"Yabo",
			"Binji",
			"Bodinga",
			"Dange Shuni",
			"Goronyo",
			"Gada",
		],
		branches: [
			{
				branch: "SOKOTO BRANCH",
				sol: "127",
			},
		],
	},
	{
		state: "Plateau",
		alias: "plateau",
		lgas: [
			"Bokkos",
			"Barkin Ladi",
			"Bassa",
			"Jos East",
			"Jos North",
			"Jos South",
			"Kanam",
			"Kanke",
			"Langtang South",
			"Langtang North",
			"Mangu",
			"Mikang",
			"Pankshin",
			"Qua'an Pan",
			"Riyom",
			"Shendam",
			"Wase",
		],
		branches: [
			{
				branch: "BRITISH AMERICAN JUNCTION JOS",
				sol: "088",
			},
			{
				branch: "BEACH ROAD JOS BRANCH",
				sol: "186",
			},
			{
				branch: "MURTALA MOHAMMED WAY JOS BRANCH",
				sol: "203",
			},
		],
	},
	{
		state: "Taraba",
		alias: "taraba",
		lgas: [
			"Ardo Kola",
			"Bali",
			"Donga",
			"Gashaka",
			"Gassol",
			"Ibi",
			"Jalingo",
			"Karim Lamido",
			"Kumi",
			"Lau",
			"Sardauna",
			"Takum",
			"Ussa",
			"Wukari",
			"Yorro",
			"Zing",
		],
		branches: [
			{
				branch: "JALINGO BRANCH",
				sol: "116",
			},
		],
	},
	{
		state: "Yobe",
		alias: "yobe",
		lgas: [
			"Bade",
			"Bursari",
			"Damaturu",
			"Fika",
			"Fune",
			"Geidam",
			"Gujba",
			"Gulani",
			"Jakusko",
			"Karasuwa",
			"Machina",
			"Nangere",
			"Nguru",
			"Potiskum",
			"Tarmuwa",
			"Yunusari",
			"Yusufari",
		],
		branches: [
			{
				branch: "DAMATURU 1 BRANCH",
				sol: "117",
			},
		],
	},
	{
		state: "Zamfara",
		alias: "zamfara",
		lgas: [
			"Anka",
			"Birnin Magaji/Kiyaw",
			"Bakura",
			"Bukkuyum",
			"Bungudu",
			"Gummi",
			"Gusau",
			"Kaura Namoda",
			"Maradun",
			"Shinkafi",
			"Maru",
			"Talata Mafara",
			"Tsafe",
			"Zurmi",
		],
		branches: [
			{
				branch: "GUSAU 1 BRANCH",
				sol: "125",
			},
		],
	},
	{
		state: "Lagos",
		alias: "lagos",
		lgas: [
			"Agege",
			"Ajeromi-Ifelodun",
			"Alimosho",
			"Amuwo-Odofin",
			"Badagry",
			"Apapa",
			"Epe",
			"Eti Osa",
			"Ibeju-Lekki",
			"Ifako-Ijaiye",
			"Ikeja",
			"Ikorodu",
			"Kosofe",
			"Lagos Island",
			"Mushin",
			"Lagos Mainland",
			"Ojo",
			"Oshodi-Isolo",
			"Shomolu",
			"Surulere Lagos State",
		],
		branches: [
			{
				branch: "HEAD OFFICE BRANCH",
				sol: "001",
			},
			{
				branch: "APAPA I BRANCH",
				sol: "006",
			},
			{
				branch: "OBA AKRAN I BRANCH",
				sol: "007",
			},
			{
				branch: "IDUMAGBO BRANCH",
				sol: "010",
			},
			{
				branch: "ADEOLA ODEKU BRANCH",
				sol: "023",
			},
			{
				branch: "ALABA I BRANCH",
				sol: "026",
			},
			{
				branch: "AWOLOWO I BRANCH",
				sol: "027",
			},
			{
				branch: "ALAGBADO BRANCH",
				sol: "030",
			},
			{
				branch: "ALLEN I BRANCH",
				sol: "031",
			},
			{
				branch: "OKE-ARIN BRANCH",
				sol: "032",
			},
			{
				branch: "AJAH BRANCH",
				sol: "034",
			},
			{
				branch: "IDUMOTA",
				sol: "037",
			},
			{
				branch: "BROAD STREET BRANCH",
				sol: "039",
			},
			{
				branch: "AIRPORT RD BRANCH",
				sol: "051",
			},
			{
				branch: "APAPA II BRANCH",
				sol: "056",
			},
			{
				branch: "IKORODU BRANCH",
				sol: "061",
			},
			{
				branch: "MOTORWAYS BRANCH",
				sol: "065",
			},
			{
				branch: "OJO BRANCH",
				sol: "066",
			},
			{
				branch: "SANUSI FAFUNWA BRANCH",
				sol: "069",
			},
			{
				branch: " MUSHIN BRANCH",
				sol: "075",
			},
			{
				branch: "AKOWONJO BRANCH",
				sol: "076",
			},
			{
				branch: "ADENIRAN OGUNSANYA BRANCH",
				sol: "079",
			},
			{
				branch: "OGBA BRANCH",
				sol: "081",
			},
			{
				branch: "YABA BRANCH",
				sol: "082",
			},
			{
				branch: "ONIRU BRANCH",
				sol: "083",
			},
			{
				branch: "MATORI BRANCH",
				sol: "087",
			},
			{
				branch: "OKOTA BRANCH",
				sol: "091",
			},
			{
				branch: "LEKKI  BRANCH",
				sol: "095",
			},
			{
				branch: "KETU BRANCH",
				sol: "107",
			},
			{
				branch: "ADEOLA HOPEWELL BRANCH",
				sol: "110",
			},
			{
				branch: "AGEGE BRANCH",
				sol: "114",
			},
			{
				branch: "ORILE COKER BRANCH",
				sol: "118",
			},
			{
				branch: "MACARTHY BRANCH",
				sol: "119",
			},
			{
				branch: "IDIMU BRANCH",
				sol: "129",
			},
			{
				branch: "DETOKUNBO ADEMOLA BRANCH",
				sol: "132",
			},
			{
				branch: "MARINA BRANCH",
				sol: "135",
			},
			{
				branch: "IKEJA GRA BRANCH",
				sol: "137",
			},
			{
				branch: "WHARF",
				sol: "142",
			},
			{
				branch: "KINGSWAY ROAD BRANCH",
				sol: "143",
			},
			{
				branch: "ONIPAN BRANCH",
				sol: "145",
			},
			{
				branch: "DAVIES STREET BRANCH",
				sol: "146",
			},
			{
				branch: "TRADE FAIR II BRANCH",
				sol: "147",
			},
			{
				branch: "OSOLO WAY BRANCH",
				sol: "152",
			},
			{
				branch: "RANDLE RD BRANCH",
				sol: "154",
			},
			{
				branch: "ASPAMDA II BRANCH",
				sol: "155",
			},
			{
				branch: "MOBOLAJI BANK ANTHONY BRANCH",
				sol: "157",
			},
			{
				branch: "IKEJA LOCAL AIRPORT BRANCH",
				sol: "158",
			},
			{
				branch: "OROYINYIN BRANCH",
				sol: "161",
			},
			{
				branch: "ALABA II BRANCH",
				sol: "163",
			},
			{
				branch: "OREGUN BRANCH",
				sol: "164",
			},
			{
				branch: "OSHODI BRANCH",
				sol: "165",
			},
			{
				branch: "JOSEPH STREET BRANCH",
				sol: "167",
			},
			{
				branch: "IDDO BRANCH",
				sol: "169",
			},
			{
				branch: "FESTAC BRANCH",
				sol: "174",
			},
			{
				branch: "LEKKI CHEVRON BRANCH",
				sol: "175",
			},
			{
				branch: "OGUDU BRANCH",
				sol: "176",
			},
			{
				branch: "ILUPEJU BRANCH",
				sol: "181",
			},
			{
				branch: "LEKKI ADMIRALTY WAY BRANCH",
				sol: "280",
			},
			{
				branch: "AMUWO-ODOFIN BRANCH",
				sol: "282",
			},
			{
				branch: "GBAGADA BRANCH",
				sol: "283",
			},
		],
	},
	{
		state: "Katsina",
		alias: "katsina",
		lgas: [
			"Bakori",
			"Batagarawa",
			"Batsari",
			"Baure",
			"Bindawa",
			"Charanchi",
			"Danja",
			"Dandume",
			"Dan Musa",
			"Daura",
			"Dutsi",
			"Dutsin Ma",
			"Faskari",
			"Funtua",
			"Ingawa",
			"Jibia",
			"Kafur",
			"Kaita",
			"Kankara",
			"Kankia",
			"Katsina",
			"Kurfi",
			"Kusada",
			"Mai'Adua",
			"Malumfashi",
			"Mani",
			"Mashi",
			"Matazu",
			"Musawa",
			"Rimi",
			"Sabuwa",
			"Safana",
			"Sandamu",
			"Zango",
		],
	},
	{
		state: "Kwara",
		alias: "kwara",
		lgas: [
			"Asa",
			"Baruten",
			"Edu",
			"Ilorin East",
			"Ifelodun",
			"Ilorin South",
			"Ekiti Kwara State",
			"Ilorin West",
			"Irepodun",
			"Isin",
			"Kaiama",
			"Moro",
			"Offa",
			"Oke Ero",
			"Oyun",
			"Pategi",
		],
		branches: [
			{
				branch: "MURTALA MOHAMMED ROAD  BRANCH",
				sol: "121",
			},
			{
				branch: "IBRAHIM TAIWO RD BRANCH",
				sol: "151",
			},
			{
				branch: "ABDULAZEEZ ATTAH, ILORIN BRANCH",
				sol: "152",
			},
		],
	},
	{
		state: "Nasarawa",
		alias: "nasarawa",
		lgas: [
			"Akwanga",
			"Awe",
			"Doma",
			"Karu",
			"Keana",
			"Keffi",
			"Lafia",
			"Kokona",
			"Nasarawa Egon",
			"Nasarawa",
			"Obi",
			"Toto",
			"Wamba",
		],
		branches: [
			{
				branch: "LAFIA II BRANCH",
				sol: "202",
			},
			{
				branch: "SABON TASHA KEFFI BRANCH",
				sol: "221",
			},
			{
				branch: "KARU BRANCH",
				sol: "285",
			},
		],
	},
	{
		state: "Niger",
		alias: "niger",
		lgas: [
			"Agaie",
			"Agwara",
			"Bida",
			"Borgu",
			"Bosso",
			"Chanchaga",
			"Edati",
			"Gbako",
			"Gurara",
			"Katcha",
			"Kontagora",
			"Lapai",
			"Lavun",
			"Mariga",
			"Magama",
			"Mokwa",
			"Mashegu",
			"Moya",
			"Paikoro",
			"Rafi",
			"Rijau",
			"Shiroro",
			"Suleja",
			"Tafa",
			"Wushishi",
		],
		branches: [
			{
				branch: "MINNA 1 BRANCH",
				sol: "074",
			},
			{
				branch: "SULEJA BRANCH",
				sol: "232",
			},
		],
	},
	{
		state: "Abia",
		alias: "abia",
		lgas: [
			"Aba North",
			"Arochukwu",
			"Aba South",
			"Bende",
			"Isiala Ngwa North",
			"Ikwuano",
			"Isiala Ngwa South",
			"Isuikwuato",
			"Obi Ngwa",
			"Ohafia",
			"Osisioma",
			"Ugwunagbo",
			"Ukwa East",
			"Ukwa West",
			"Umuahia North",
			"Umuahia South",
			"Umu Nneochi",
		],
		branches: [
			{
				branch: "ASA ROAD I BRANCH",
				sol: "013",
			},
			{
				branch: "FAULKS ROAD BRANCH",
				sol: "045",
			},
			{
				branch: "UMUAHIA I BRANCH",
				sol: "078",
			},
			{
				branch: "BRASS ROAD BRANCH",
				sol: "237",
			},
			{
				branch: "UMUAHIA II BRANCH",
				sol: "267",
			},
		],
	},
];

export const occupations = [
	"Accountant",
	"Actor",
	"Actuary",
	"Adhesive Bonding Machine Tender",
	"Adjudicator",
	"Administrative Assistant",
	"Administrative Services Manager",
	"Adult Education Teacher",
	"Advertising Manager",
	"Advertising Sales Agent",
	"Aerobics Instructor",
	"Aerospace Engineer",
	"Aerospace Engineering Technician",
	"Agent",
	"Agricultural Engineer",
	"Agricultural Equipment Operator",
	"Agricultural Grader",
	"Agricultural Inspector",
	"Agricultural Manager",
	"Agricultural Sciences Teacher",
	"Agricultural Sorter",
	"Agricultural Technician",
	"Agricultural Worker",
	"Air Conditioning Installer",
	"Air Conditioning Mechanic",
	"Air Traffic Controller",
	"Aircraft Cargo Handling Supervisor",
	"Aircraft Mechanic",
	"Aircraft Service Technician",
	"Airline Copilot",
	"Airline Pilot",
	"Ambulance Dispatcher",
	"Ambulance Driver",
	"Amusement Machine Servicer",
	"Anesthesiologist",
	"Animal Breeder",
	"Animal Control Worker",
	"Animal Scientist",
	"Animal Trainer",
	"Animator",
	"Answering Service Operator",
	"Anthropologist",
	"Apparel Patternmaker",
	"Apparel Worker",
	"Arbitrator",
	"Archeologist",
	"Architect",
	"Architectural Drafter",
	"Architectural Manager",
	"Archivist",
	"Art Director",
	"Art Teacher",
	"Artist",
	"Assembler",
	"Astronomer",
	"Athlete",
	"Athletic Trainer",
	"Atm Machine Repairer",
	"Atmospheric Scientist",
	"Attendant",
	"Audio And Video Equipment Technician",
	"Audio-Visual And Multimedia Collections Specialist",
	"Audiologist",
	"Auditor",
	"Author",
	"Auto Damage Insurance Appraiser",
	"Automotive And Watercraft Service Attendant",
	"Automotive Glass Installer",
	"Automotive Mechanic",
	"Avionics Technician",
	"Back-End Developer",
	"Baggage Porter",
	"Bailiff",
	"Baker",
	"Barback",
	"Barber",
	"Bartender",
	"Basic Education Teacher",
	"Behavioral Disorder Counselor",
	"Bellhop",
	"Bench Carpenter",
	"Bicycle Repairer",
	"Bill And Account Collector",
	"Billing And Posting Clerk",
	"Biochemist",
	"Biological Technician",
	"Biomedical Engineer",
	"Biophysicist",
	"Blaster",
	"Blending Machine Operator",
	"Blockmason",
	"Boiler Operator",
	"Boilermaker",
	"Bookkeeper",
	"Boring Machine Tool Tender",
	"Brazer",
	"Brickmason",
	"Bridge And Lock Tender",
	"Broadcast News Analyst",
	"Broadcast Technician",
	"Brokerage Clerk",
	"Budget Analyst",
	"Building Inspector",
	"Bus Mechanic",
	"Butcher",
	"Buyer",
	"Cabinetmaker",
	"Cafeteria Attendant",
	"Cafeteria Cook",
	"Camera Operator",
	"Camera Repairer",
	"Cardiovascular Technician",
	"Cargo Agent",
	"Carpenter",
	"Carpet Installer",
	"Cartographer",
	"Cashier",
	"Caster",
	"Ceiling Tile Installer",
	"Cellular Equipment Installer",
	"Cement Mason",
	"Channeling Machine Operator",
	"Chauffeur",
	"Checker",
	"Chef",
	"Chemical Engineer",
	"Chemical Plant Operator",
	"Chemist",
	"Chemistry Teacher",
	"Chief Executive",
	"Child Social Worker",
	"Childcare Worker",
	"Chiropractor",
	"Choreographer",
	"Civil Drafter",
	"Civil Engineer",
	"Civil Engineering Technician",
	"Claims Adjuster",
	"Claims Examiner",
	"Claims Investigator",
	"Cleaner",
	"Clinical Laboratory Technician",
	"Clinical Laboratory Technologist",
	"Clinical Psychologist",
	"Coating Worker",
	"Coatroom Attendant",
	"Coil Finisher",
	"Coil Taper",
	"Coil Winder",
	"Coin Machine Servicer",
	"Commercial Diver",
	"Commercial Pilot",
	"Commodities Sales Agent",
	"Communications Equipment Operator",
	"Communications Teacher",
	"Community Association Manager",
	"Community Service Manager",
	"Compensation And Benefits Manager",
	"Compliance Officer",
	"Composer",
	"Computer Hardware Engineer",
	"Computer Network Architect",
	"Computer Operator",
	"Computer Programmer",
	"Computer Science Teacher",
	"Computer Support Specialist",
	"Computer Systems Administrator",
	"Computer Systems Analyst",
	"Concierge",
	"Conciliator",
	"Concrete Finisher",
	"Conservation Science Teacher",
	"Conservation Scientist",
	"Conservation Worker",
	"Conservator",
	"Construction Inspector",
	"Construction Manager",
	"Construction Painter",
	"Construction Worker",
	"Continuous Mining Machine Operator",
	"Convention Planner",
	"Conveyor Operator",
	"Cook",
	"Cooling Equipment Operator",
	"Copy Marker",
	"Correctional Officer",
	"Correctional Treatment Specialist",
	"Correspondence Clerk",
	"Correspondent",
	"Cosmetologist",
	"Cost Estimator",
	"Costume Attendant",
	"Counseling Psychologist",
	"Counselor",
	"Courier",
	"Court Reporter",
	"Craft Artist",
	"Crane Operator",
	"Credit Analyst",
	"Credit Checker",
	"Credit Counselor",
	"Criminal Investigator",
	"Criminal Justice Teacher",
	"Crossing Guard",
	"Curator",
	"Custom Sewer",
	"Customer Service Representative",
	"Cutter",
	"Cutting Machine Operator",
	"Dancer",
	"Data Entry Keyer",
	"Database Administrator",
	"Decorating Worker",
	"Delivery Services Driver",
	"Demonstrator",
	"Dental Assistant",
	"Dental Hygienist",
	"Dental Laboratory Technician",
	"Dentist",
	"Dermatologist",
	"Derrick Operator",
	"Designer",
	"Desktop Publisher",
	"Detective",
	"Developer",
	"Diagnostic Medical Sonographer",
	"Die Maker",
	"Diesel Engine Specialist",
	"Dietetic Technician",
	"Dietitian",
	"Dinkey Operator",
	"Director",
	"Dishwasher",
	"Dispatcher",
	"Doctor",
	"Door-To-Door Sales Worker",
	"Drafter",
	"Dragline Operator",
	"Drama Teacher",
	"Dredge Operator",
	"Dressing Room Attendant",
	"Dressmaker",
	"Drier Operator",
	"Drilling Machine Tool Operator",
	"Dry-Cleaning Worker",
	"Drywall Installer",
	"Dyeing Machine Operator",
	"Earth Driller",
	"Economics Teacher",
	"Economist",
	"Editor",
	"Education Administrator",
	"Electric Motor Repairer",
	"Electrical Electronics Drafter",
	"Electrical Engineer",
	"Electrical Equipment Assembler",
	"Electrical Installer",
	"Electrical Power-Line Installer",
	"Electrician",
	"Electro-Mechanical Technician",
	"Elementary School Teacher",
	"Elevator Installer",
	"Elevator Repairer",
	"Embalmer",
	"Emergency Management Director",
	"Emergency Medical Technician",
	"Engine Assembler",
	"Engineer",
	"Engineering Manager",
	"Engineering Teacher",
	"English Language Teacher",
	"Engraver",
	"Entertainment Attendant",
	"Environmental Engineer",
	"Environmental Science Teacher",
	"Environmental Scientist",
	"Epidemiologist",
	"Escort",
	"Etcher",
	"Event Planner",
	"Excavating Operator",
	"Executive Administrative Assistant",
	"Executive Secretary",
	"Exhibit Designer",
	"Expediting Clerk",
	"Explosives Worker",
	"Extraction Worker",
	"Fabric Mender",
	"Fabric Patternmaker",
	"Fabricator",
	"Faller",
	"Family Practitioner",
	"Family Social Worker",
	"Family Therapist",
	"Farm Advisor",
	"Farm Equipment Mechanic",
	"Farm Labor Contractor",
	"Farmer",
	"Farmworker",
	"Fashion Designer",
	"Fast Food Cook",
	"Fence Erector",
	"Fiberglass Fabricator",
	"Fiberglass Laminator",
	"File Clerk",
	"Filling Machine Operator",
	"Film And Video Editor",
	"Financial Analyst",
	"Financial Examiner",
	"Financial Manager",
	"Financial Services Sales Agent",
	"Fine Artist",
	"Fire Alarm System Installer",
	"Fire Dispatcher",
	"Fire Inspector",
	"Fire Investigator",
	"Firefighter",
	"Fish And Game Warden",
	"Fish Cutter",
	"Fish Trimmer",
	"Fisher",
	"Fitness Studies Teacher",
	"Fitness Trainer",
	"Flight Attendant",
	"Floor Finisher",
	"Floor Layer",
	"Floor Sander",
	"Floral Designer",
	"Food Batchmaker",
	"Food Cooking Machine Operator",
	"Food Preparation Worker",
	"Food Science Technician",
	"Food Scientist",
	"Food Server",
	"Food Service Manager",
	"Food Technologist",
	"Foreign Language Teacher",
	"Foreign Literature Teacher",
	"Forensic Science Technician",
	"Forest Fire Inspector",
	"Forest Fire Prevention Specialist",
	"Forest Worker",
	"Forester",
	"Forestry Teacher",
	"Forging Machine Setter",
	"Foundry Coremaker",
	"Freight Agent",
	"Freight Mover",
	"Front-End Developer",
	"Fundraising Manager",
	"Funeral Attendant",
	"Funeral Director",
	"Funeral Service Manager",
	"Furnace Operator",
	"Furnishings Worker",
	"Furniture Finisher",
	"Gaming Booth Cashier",
	"Gaming Cage Worker",
	"Gaming Change Person",
	"Gaming Dealer",
	"Gaming Investigator",
	"Gaming Manager",
	"Gaming Surveillance Officer",
	"Garment Mender",
	"Garment Presser",
	"Gas Compressor",
	"Gas Plant Operator",
	"Gas Pumping Station Operator",
	"General Manager",
	"General Practitioner",
	"Geographer",
	"Geography Teacher",
	"Geological Engineer",
	"Geological Technician",
	"Geoscientist",
	"Glazier",
	"Government Program Eligibility Interviewer",
	"Graduate Teaching Assistant",
	"Graphic Designer",
	"Groundskeeper",
	"Groundskeeping Worker",
	"Gynecologist",
	"Hairdresser",
	"Hairstylist",
	"Hand Grinding Worker",
	"Hand Laborer",
	"Hand Packager",
	"Hand Packer",
	"Hand Polishing Worker",
	"Hand Sewer",
	"Hazardous Materials Removal Worker",
	"Head Cook",
	"Health And Safety Engineer",
	"Health Educator",
	"Health Information Technician",
	"Health Services Manager",
	"Health Specialties Teacher",
	"Healthcare Social Worker",
	"Hearing Officer",
	"Heat Treating Equipment Setter",
	"Heating Installer",
	"Heating Mechanic",
	"Heavy Truck Driver",
	"Highway Maintenance Worker",
	"Historian",
	"History Teacher",
	"Hoist And Winch Operator",
	"Home Appliance Repairer",
	"Home Economics Teacher",
	"Home Entertainment Installer",
	"Home Health Aide",
	"Home Management Advisor",
	"Host",
	"Hostess",
	"Hostler",
	"Hotel Desk Clerk",
	"Housekeeping Cleaner",
	"Human Resources Assistant",
	"Human Resources Manager",
	"Human Service Assistant",
	"Hunter",
	"Hydrologist",
	"Illustrator",
	"Industrial Designer",
	"Industrial Engineer",
	"Industrial Engineering Technician",
	"Industrial Machinery Mechanic",
	"Industrial Production Manager",
	"Industrial Truck Operator",
	"Industrial-Organizational Psychologist",
	"Information Clerk",
	"Information Research Scientist",
	"Information Security Analyst",
	"Information Systems Manager",
	"Inspector",
	"Instructional Coordinator",
	"Instructor",
	"Insulation Worker",
	"Insurance Claims Clerk",
	"Insurance Sales Agent",
	"Insurance Underwriter",
	"Intercity Bus Driver",
	"Interior Designer",
	"Internist",
	"Interpreter",
	"Interviewer",
	"Investigator",
	"Jailer",
	"Janitor",
	"Jeweler",
	"Judge",
	"Judicial Law Clerk",
	"Kettle Operator",
	"Kiln Operator",
	"Kindergarten Teacher",
	"Laboratory Animal Caretaker",
	"Landscape Architect",
	"Landscaping Worker",
	"Lathe Setter",
	"Laundry Worker",
	"Law Enforcement Teacher",
	"Law Teacher",
	"Lawyer",
	"Layout Worker",
	"Leather Worker",
	"Legal Assistant",
	"Legal Secretary",
	"Legislator",
	"Librarian",
	"Library Assistant",
	"Library Science Teacher",
	"Library Technician",
	"Licensed Practical Nurse",
	"Licensed Vocational Nurse",
	"Life Scientist",
	"Lifeguard",
	"Light Truck Driver",
	"Line Installer",
	"Literacy Teacher",
	"Literature Teacher",
	"Loading Machine Operator",
	"Loan Clerk",
	"Loan Interviewer",
	"Loan Officer",
	"Lobby Attendant",
	"Locker Room Attendant",
	"Locksmith",
	"Locomotive Engineer",
	"Locomotive Firer",
	"Lodging Manager",
	"Log Grader",
	"Logging Equipment Operator",
	"Logistician",
	"Machine Feeder",
	"Machinist",
	"Magistrate Judge",
	"Magistrate",
	"Maid",
	"Mail Clerk",
	"Mail Machine Operator",
	"Mail Superintendent",
	"Maintenance Painter",
	"Maintenance Worker",
	"Makeup Artist",
	"Management Analyst",
	"Manicurist",
	"Manufactured Building Installer",
	"Mapping Technician",
	"Marble Setter",
	"Marine Engineer",
	"Marine Oiler",
	"Market Research Analyst",
	"Marketing Manager",
	"Marketing Specialist",
	"Marriage Therapist",
	"Massage Therapist",
	"Material Mover",
	"Materials Engineer",
	"Materials Scientist",
	"Mathematical Science Teacher",
	"Mathematical Technician",
	"Mathematician",
	"Maxillofacial Surgeon",
	"Measurer",
	"Meat Cutter",
	"Meat Packer",
	"Meat Trimmer",
	"Mechanical Door Repairer",
	"Mechanical Drafter",
	"Mechanical Engineer",
	"Mechanical Engineering Technician",
	"Mediator",
	"Medical Appliance Technician",
	"Medical Assistant",
	"Medical Equipment Preparer",
	"Medical Equipment Repairer",
	"Medical Laboratory Technician",
	"Medical Laboratory Technologist",
	"Medical Records Technician",
	"Medical Scientist",
	"Medical Secretary",
	"Medical Services Manager",
	"Medical Transcriptionist",
	"Meeting Planner",
	"Mental Health Counselor",
	"Mental Health Social Worker",
	"Merchandise Displayer",
	"Messenger",
	"Metal Caster",
	"Metal Patternmaker",
	"Metal Pickling Operator",
	"Metal Pourer",
	"Metal Worker",
	"Metal-Refining Furnace Operator",
	"Metal-Refining Furnace Tender",
	"Meter Reader",
	"Microbiologist",
	"Middle School Teacher",
	"Milling Machine Setter",
	"Millwright",
	"Mine Cutting Machine Operator",
	"Mine Shuttle Car Operator",
	"Mining Engineer",
	"Mining Safety Engineer",
	"Mining Safety Inspector",
	"Mining Service Unit Operator",
	"Mixing Machine Setter",
	"Mobile Heavy Equipment Mechanic",
	"Mobile Home Installer",
	"Model Maker",
	"Model",
	"Molder",
	"Mortician",
	"Motel Desk Clerk",
	"Motion Picture Projectionist",
	"Motorboat Mechanic",
	"Motorboat Operator",
	"Motorboat Service Technician",
	"Motorcycle Mechanic",
	"Movers",
	"Multimedia Artist",
	"Museum Technician",
	"Music Director",
	"Music Teacher",
	"Musical Instrument Repairer",
	"Musician",
	"Natural Sciences Manager",
	"Naval Architect",
	"Network Systems Administrator",
	"New Accounts Clerk",
	"News Vendor",
	"Nonfarm Animal Caretaker",
	"Nuclear Engineer",
	"Nuclear Medicine Technologist",
	"Nuclear Power Reactor Operator",
	"Nuclear Technician",
	"Nursing Aide",
	"Nursing Instructor",
	"Nursing Teacher",
	"Nutritionist",
	"Obstetrician",
	"Occupational Health And Safety Specialist",
	"Occupational Health And Safety Technician",
	"Occupational Therapist",
	"Occupational Therapy Aide",
	"Occupational Therapy Assistant",
	"Offbearer",
	"Office Clerk",
	"Office Machine Operator",
	"Operating Engineer",
	"Operations Manager",
	"Operations Research Analyst",
	"Ophthalmic Laboratory Technician",
	"Optician",
	"Optometrist",
	"Oral Surgeon",
	"Order Clerk",
	"Order Filler",
	"Orderly",
	"Ordnance Handling Expert",
	"Orthodontist",
	"Orthotist",
	"Others",
	"Outdoor Power Equipment Mechanic",
	"Oven Operator",
	"Packaging Machine Operator",
	"Painter",
	"Painting Worker",
	"Paper Goods Machine Setter",
	"Paperhanger",
	"Paralegal",
	"Paramedic",
	"Parking Enforcement Worker",
	"Parking Lot Attendant",
	"Parts Salesperson",
	"Paving Equipment Operator",
	"Payroll Clerk",
	"Pediatrician",
	"Pedicurist",
	"Personal Care Aide",
	"Personal Chef",
	"Personal Financial Advisor",
	"Personal Trainer",
	"Pest Control Worker",
	"Pesticide Applicator",
	"Pesticide Handler",
	"Pesticide Sprayer",
	"Petroleum Engineer",
	"Petroleum Gauger",
	"Petroleum Pump System Operator",
	"Petroleum Refinery Operator",
	"Petroleum Technician",
	"Pharmacist",
	"Pharmacy Aide",
	"Pharmacy Technician",
	"Philosophy Teacher",
	"Photogrammetrist",
	"Photographer",
	"Photographic Process Worker",
	"Photographic Processing Machine Operator",
	"Physical Therapist Aide",
	"Physical Therapist Assistant",
	"Physical Therapist",
	"Physician Assistant",
	"Physician",
	"Physicist",
	"Physics Teacher",
	"Pile-Driver Operator",
	"Pipefitter",
	"Pipelayer",
	"Planing Machine Operator",
	"Planning Clerk",
	"Plant Operator",
	"Plant Scientist",
	"Plasterer",
	"Plastic Patternmaker",
	"Plastic Worker",
	"Plumber",
	"Podiatrist",
	"Police Dispatcher",
	"Police Officer",
	"Policy Processing Clerk",
	"Political Science Teacher",
	"Political Scientist",
	"Postal Service Clerk",
	"Postal Service Mail Carrier",
	"Postal Service Mail Processing Machine Operator",
	"Postal Service Mail Processor",
	"Postal Service Mail Sorter",
	"Postmaster",
	"Postsecondary Teacher",
	"Poultry Cutter",
	"Poultry Trimmer",
	"Power Dispatcher",
	"Power Distributor",
	"Power Plant Operator",
	"Power Tool Repairer",
	"Precious Stone Worker",
	"Precision Instrument Repairer",
	"Prepress Technician",
	"Preschool Teacher",
	"Priest",
	"Print Binding Worker",
	"Printing Press Operator",
	"Private Detective",
	"Probation Officer",
	"Procurement Clerk",
	"Producer",
	"Product Promoter",
	"Product Manager",
	"Production Clerk",
	"Production Occupation",
	"Proofreader",
	"Property Manager",
	"Prosthetist",
	"Prosthodontist",
	"Psychiatric Aide",
	"Psychiatric Technician",
	"Psychiatrist",
	"Psychologist",
	"Psychology Teacher",
	"Public Relations Manager",
	"Public Relations Specialist",
	"Pump Operator",
	"Purchasing Agent",
	"Purchasing Manager",
	"Radiation Therapist",
	"Radio Announcer",
	"Radio Equipment Installer",
	"Radio Operator",
	"Radiologic Technician",
	"Radiologic Technologist",
	"Rail Car Repairer",
	"Rail Transportation Worker",
	"Rail Yard Engineer",
	"Rail-Track Laying Equipment Operator",
	"Railroad Brake Operator",
	"Railroad Conductor",
	"Railroad Police",
	"Rancher",
	"Real Estate Appraiser",
	"Real Estate Broker",
	"Real Estate Manager",
	"Real Estate Sales Agent",
	"Receiving Clerk",
	"Receptionist",
	"Record Clerk",
	"Recreation Teacher",
	"Recreation Worker",
	"Recreational Therapist",
	"Recreational Vehicle Service Technician",
	"Recyclable Material Collector",
	"Referee",
	"Refractory Materials Repairer",
	"Refrigeration Installer",
	"Refrigeration Mechanic",
	"Refuse Collector",
	"Regional Planner",
	"Registered Nurse",
	"Rehabilitation Counselor",
	"Reinforcing Iron Worker",
	"Reinforcing Rebar Worker",
	"Religion Teacher",
	"Religious Activities Director",
	"Religious Worker",
	"Rental Clerk",
	"Repair Worker",
	"Reporter",
	"Residential Advisor",
	"Resort Desk Clerk",
	"Respiratory Therapist",
	"Respiratory Therapy Technician",
	"Retail Buyer",
	"Retail Salesperson",
	"Revenue Agent",
	"Rigger",
	"Rock Splitter",
	"Rolling Machine Tender",
	"Roof Bolter",
	"Roofer",
	"Rotary Drill Operator",
	"Roustabout",
	"Safe Repairer",
	"Sailor",
	"Sales Engineer",
	"Sales Manager",
	"Sales Representative",
	"Sampler",
	"Sawing Machine Operator",
	"Scaler",
	"School Bus Driver",
	"School Psychologist",
	"School Social Worker",
	"Scout Leader",
	"Sculptor",
	"Secondary Education Teacher",
	"Secondary School Teacher",
	"Secretary",
	"Securities Sales Agent",
	"Security Guard",
	"Security System Installer",
	"Segmental Paver",
	"Self-Enrichment Education Teacher",
	"Semiconductor Processor",
	"Septic Tank Servicer",
	"Set Designer",
	"Sewer Pipe Cleaner",
	"Sewing Machine Operator",
	"Shampooer",
	"Shaper",
	"Sheet Metal Worker",
	"Sheriff'S Patrol Officer",
	"Ship Captain",
	"Ship Engineer",
	"Ship Loader",
	"Shipmate",
	"Shipping Clerk",
	"Shoe Machine Operator",
	"Shoe Worker",
	"Short Order Cook",
	"Signal Operator",
	"Signal Repairer",
	"Singer",
	"Ski Patrol",
	"Skincare Specialist",
	"Slaughterer",
	"Slicing Machine Tender",
	"Slot Supervisor",
	"Social Science Research Assistant",
	"Social Sciences Teacher",
	"Social Scientist",
	"Social Service Assistant",
	"Social Service Manager",
	"Social Work Teacher",
	"Social Worker",
	"Sociologist",
	"Sociology Teacher",
	"Software Developer",
	"Software Engineer",
	"Soil Scientist",
	"Solderer",
	"Sorter",
	"Sound Engineering Technician",
	"Space Scientist",
	"Special Education Teacher",
	"Speech-Language Pathologist",
	"Sports Book Runner",
	"Sports Entertainer",
	"Sports Performer",
	"Stationary Engineer",
	"Statistical Assistant",
	"Statistician",
	"Steamfitter",
	"Stock Clerk",
	"Stock Mover",
	"Stonemason",
	"Street Vendor",
	"Streetcar Operator",
	"Structural Iron Worker",
	"Structural Metal Fabricator",
	"Structural Metal Fitter",
	"Structural Steel Worker",
	"Stucco Mason",
	"Substance Abuse Counselor",
	"Substance Abuse Social Worker",
	"Subway Operator",
	"Surfacing Equipment Operator",
	"Surgeon",
	"Surgical Technologist",
	"Survey Researcher",
	"Surveying Technician",
	"Surveyor",
	"Switch Operator",
	"Switchboard Operator",
	"Tailor",
	"Tamping Equipment Operator",
	"Tank Car Loader",
	"Taper",
	"Tax Collector",
	"Tax Examiner",
	"Tax Preparer",
	"Taxi Driver",
	"Teacher Assistant",
	"Teacher",
	"Team Assembler",
	"Technical Writer",
	"Telecommunications Equipment Installer",
	"Telemarketer",
	"Telephone Operator",
	"Television Announcer",
	"Teller",
	"Terrazzo Finisher",
	"Terrazzo Worker",
	"Tester",
	"Textile Bleaching Operator",
	"Textile Cutting Machine Setter",
	"Textile Knitting Machine Setter",
	"Textile Presser",
	"Textile Worker",
	"Therapist",
	"Ticket Agent",
	"Ticket Taker",
	"Tile Setter",
	"Timekeeping Clerk",
	"Timing Device Assembler",
	"Tire Builder",
	"Tire Changer",
	"Tire Repairer",
	"Title Abstractor",
	"Title Examiner",
	"Title Searcher",
	"Tobacco Roasting Machine Operator",
	"Tool Filer",
	"Tool Grinder",
	"Tool Maker",
	"Tool Sharpener",
	"Tour Guide",
	"Tower Equipment Installer",
	"Tower Operator",
	"Track Switch Repairer",
	"Tractor Operator",
	"Tractor-Trailer Truck Driver",
	"Traffic Clerk",
	"Traffic Technician",
	"Training And Development Manager",
	"Training And Development Specialist",
	"Transit Police",
	"Translator",
	"Transportation Equipment Painter",
	"Transportation Inspector",
	"Transportation Security Screener",
	"Transportation Worker",
	"Trapper",
	"Travel Agent",
	"Travel Clerk",
	"Travel Guide",
	"Tree Pruner",
	"Tree Trimmer",
	"Trimmer",
	"Truck Loader",
	"Truck Mechanic",
	"Tuner",
	"Turning Machine Tool Operator",
	"Tutor",
	"Typist",
	"Umpire",
	"Undertaker",
	"Upholsterer",
	"Urban Planner",
	"Usher",
	"Ux Designer",
	"Valve Installer",
	"Vending Machine Servicer",
	"Veterinarian",
	"Veterinary Assistant",
	"Veterinary Technician",
	"Vocational Counselor",
	"Vocational Education Teacher",
	"Waiter",
	"Waitress",
	"Watch Repairer",
	"Water Treatment Plant Operator",
	"Weaving Machine Setter",
	"Web Developer",
	"Weigher",
	"Welder",
	"Wellhead Pumper",
	"Wholesale Buyer",
	"Wildlife Biologist",
	"Window Trimmer",
	"Wood Patternmaker",
	"Woodworker",
	"Word Processor",
	"Writer",
	"Yardmaster",
	"Zoologist",
];

export const genders = [
	{ label: "Male", value: "M" },
	{ label: "Female", value: "F" },
];

export const positions = [
	"Managing Director",
	"Chief Executive Officer",
	"Major Shareholder",
	"Director",
];

export const nationalities = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua And Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia",
	"Bosnia And Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cape Verde",
	"Cayman Islands",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (keeling) Islands",
	"Colombia",
	"Comoros",
	"Congo",
	"Congo, The Democratic Republic Of The",
	"Cook Islands",
	"Costa Rica",
	"Cote d'Ivoire",
	"Croatia",
	"Cuba",
	"Cyprus",
	"Czech Republic",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"East Timor",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Ethiopia",
	"Falkland Islands (malvinas)",
	"Faroe Islands",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guinea",
	"Guinea-bissau",
	"Guyana",
	"Haiti",
	"Heard Island And Mcdonald Islands",
	"Holy See (vatican City State)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran, Islamic Republic Of",
	"Iraq",
	"Ireland",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jordan",
	"Kazakstan",
	"Kenya",
	"Kiribati",
	"Korea, Democratic People's Republic Of",
	"Korea, Republic Of",
	"Kosovo",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libyan Arab Jamahiriya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macau",
	"Macedonia, The Former Yugoslav Republic Of",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia, Federated States Of",
	"Moldova, Republic Of",
	"Monaco",
	"Mongolia",
	"Montserrat",
	"Montenegro",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"Netherlands Antilles",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestinian Territory, Occupied",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Reunion",
	"Romania",
	"Russian Federation",
	"Rwanda",
	"Saint Helena",
	"Saint Kitts And Nevis",
	"Saint Lucia",
	"Saint Pierre And Miquelon",
	"Saint Vincent And The Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome And Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia And The South Sandwich Islands",
	"Spain",
	"Sri Lanka",
	"Sudan",
	"Suriname",
	"Svalbard And Jan Mayen",
	"Swaziland",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan, Province Of China",
	"Tajikistan",
	"Tanzania, United Republic Of",
	"Thailand",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad And Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks And Caicos Islands",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates",
	"United Kingdom",
	"United States",
	"United States Minor Outlying Islands",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela",
	"Viet Nam",
	"Virgin Islands, British",
	"Virgin Islands, U.s.",
	"Wallis And Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
];

export const titles = [
	{
		label: "ALJ",
		value: "ALHAJA",
	},
	{
		label: "ALH",
		value: "ALHAJI",
	},
	{
		label: "ARCH",
		value: "ARCHBISHOP",
	},
	{
		label: "ARC",
		value: "ARCHITECT",
	},
	{
		label: "BARR",
		value: "BARRISTER",
	},
	{
		label: "BISH",
		value: "BISHOP",
	},
	{
		label: "BRIG",
		value: "BRIGADIER",
	},
	{
		label: "CAPT",
		value: "CAPTAIN",
	},
	{
		label: "CHIEF",
		value: "CHIEF",
	},
	{
		label: "COL",
		value: "COLONEL",
	},
	{
		label: "CORP",
		value: "CORPORAL",
	},
	{
		label: "DEAC",
		value: "DEACON",
	},
	{
		label: "DR",
		value: "DOCTOR",
	},
	{
		label: "ELDER",
		value: "ELDER",
	},
	{
		label: "ENGR",
		value: "ENGINEER",
	},
	{
		label: "EVAN",
		value: "EVANGELIST",
	},
	{
		label: "EXC",
		value: "EXCELLENCY",
	},
	{
		label: "EZE",
		value: "EZE",
	},
	{
		label: "FR",
		value: "FATHER",
	},
	{
		label: "GEN",
		value: "GENERAL",
	},
	{
		label: "HEREX",
		value: "HER EXCELLENCY",
	},
	{
		label: "HERW",
		value: "HER WORSHIP",
	},
	{
		label: "HIGH",
		value: "HIGH CHIEF",
	},
	{
		label: "HISEX",
		value: "HIS EXCELLENCY",
	},
	{
		label: "HRH",
		value: "HIS ROYAL HIGHNESS",
	},
	{
		label: "HRM",
		value: "HIS ROYAL MAJESTY",
	},
	{
		label: "HISW",
		value: "HIS WORSHIP",
	},
	{
		label: "HON",
		value: "HONOURABLE",
	},
	{
		label: "IGWE",
		value: "IGWE",
	},
	{
		label: "JUST",
		value: "JUSTICE",
	},
	{
		label: "LIEUT",
		value: "LIEUTENANT",
	},
	{
		label: "LIEOL",
		value: "LIEFTENANT COLONEL",
	},
	{
		label: "LOLO",
		value: "LOLO",
	},
	{
		label: "M/S",
		value: "M/S",
	},
	{
		label: "MAJOR",
		value: "MAJOR",
	},
	{
		label: "MAGEN",
		value: "MAJOR GENERAL",
	},
	{
		label: "MAST",
		value: "MASTER",
	},
	{
		label: "MAZI",
		value: "MAZI",
	},
	{
		label: "MES",
		value: "MESSERS",
	},
	{
		label: "MINOR",
		value: "MINOR",
	},
	{
		label: "MISS",
		value: "MISS",
	},
	{
		label: "MIST",
		value: "MISTER",
	},
	{
		label: "MR",
		value: "MR",
	},
	{
		label: "M&M",
		value: "MR & MRS",
	},
	{
		label: "MRS",
		value: "MRS",
	},
	{
		label: "MS",
		value: "MS",
	},
	{
		label: "NZE",
		value: "NZE",
	},
	{
		label: "OBA",
		value: "OBA",
	},
	{
		label: "OTHER",
		value: "OTHERS",
	},
	{
		label: "OTUN",
		value: "OTUNBA",
	},
	{
		label: "PAST",
		value: "PASTOR",
	},
	{
		label: "PROF",
		value: "PROFESSOR",
	},
	{
		label: "REV",
		value: "REVEREND",
	},
	{
		label: "SERG",
		value: "SERGEANT",
	},
	{
		label: "SIR",
		value: "SIR",
	},
	{
		label: "SIS",
		value: "SISTER",
	},
	{
		label: "UEZE",
		value: "UGO-EZE",
	},
];

export const shareholderTypes = [ShareholderTypeEnum.Individual, ShareholderTypeEnum.Corporate];

export const ownership_structures = [
	"Gender Major Shareholder (Male)",
	"Gender Major Shareholder (Female)",
	"Gender of CEO (Male)",
	"Gender of CEO (Female)",
	"Gender of MD (Male)",
	"Gender of MD (Female)",
];

export const business_turnover = [
	{ label: "Less than N250 Thousand", value: "01" },
	{ label: " N250 Thousand to less than  N500 Thousand", value: "02" },
	{ label: " N500 Thousand to less than  N1 Million", value: "03" },
	{ label: " N1 Million to less than  N5 Million", value: "04" },
	{ label: " N5 Million to less than  N20 Million", value: "05" },
	{ label: " N20 Million to less than  N50 Million", value: "06" },
	{ label: " N50 Million to less than  N250 Million", value: "07" },
	{ label: " N250 Million to less than  N500 Million", value: "08" },
	{ label: " N250 Million to less than  N500 Million", value: "08" },
	{ label: "N500 Million to less than N5 Billion", value: "09" },
	{ label: "Above N5 Billion", value: "10" },
];

export const authorizationRules = [
	{ label: "Sole Signatory", value: "Sole Signatory" },
	{ label: "Both to Sign", value: "Both to Sign" },
	{ label: "Either to Sign", value: "Either to Sign" },
	{ label: "Any to Sign", value: "Any to Sign" },
	{ label: "Other", value: "Other" },
];

export const serviceRequests = [
	"Internet Banking",
	"Debit Card",
	"SMS Alert",
	"Email Alert",
	"Cheque Book",
];

export const businessRequest = [
	" Training & Mentorship",
	"Collection Solution (PoS, Payment Gateway)",
	"Advisory Service",
	"Access to digital business management tools",
	"Access to new market & Networking",
	"Access to Loans",
];

export const country_codes = [
	{
		code: "+7840",
		name: "Abkhazia",
	},
	{
		code: "+93",
		name: "Afghanistan",
	},
	{
		code: "+355",
		name: "Albania",
	},
	{
		code: "+213",
		name: "Algeria",
	},
	{
		code: "+1684",
		name: "American Samoa",
	},
	{
		code: "+376",
		name: "Andorra",
	},
	{
		code: "+244",
		name: "Angola",
	},
	{
		code: "+1264",
		name: "Anguilla",
	},
	{
		code: "+1268",
		name: "Antigua and Barbuda",
	},
	{
		code: "+54",
		name: "Argentina",
	},
	{
		code: "+374",
		name: "Armenia",
	},
	{
		code: "+297",
		name: "Aruba",
	},
	{
		code: "+247",
		name: "Ascension",
	},
	{
		code: "+61",
		name: "Australia",
	},
	{
		code: "+672",
		name: "Australian External Territories",
	},
	{
		code: "+43",
		name: "Austria",
	},
	{
		code: "+994",
		name: "Azerbaijan",
	},
	{
		code: "+1242",
		name: "Bahamas",
	},
	{
		code: "+973",
		name: "Bahrain",
	},
	{
		code: "+880",
		name: "Bangladesh",
	},
	{
		code: "+1246",
		name: "Barbados",
	},
	{
		code: "+1268",
		name: "Barbuda",
	},
	{
		code: "+375",
		name: "Belarus",
	},
	{
		code: "+32",
		name: "Belgium",
	},
	{
		code: "+501",
		name: "Belize",
	},
	{
		code: "+229",
		name: "Benin",
	},
	{
		code: "+1441",
		name: "Bermuda",
	},
	{
		code: "+975",
		name: "Bhutan",
	},
	{
		code: "+591",
		name: "Bolivia",
	},
	{
		code: "+387",
		name: "Bosnia and Herzegovina",
	},
	{
		code: "+267",
		name: "Botswana",
	},
	{
		code: "+55",
		name: "Brazil",
	},
	{
		code: "+246",
		name: "British Indian Ocean Territory",
	},
	{
		code: "+1284",
		name: "British Virgin Islands",
	},
	{
		code: "+673",
		name: "Brunei",
	},
	{
		code: "+359",
		name: "Bulgaria",
	},
	{
		code: "+226",
		name: "Burkina Faso",
	},
	{
		code: "+257",
		name: "Burundi",
	},
	{
		code: "+855",
		name: "Cambodia",
	},
	{
		code: "+237",
		name: "Cameroon",
	},
	{
		code: "+1",
		name: "Canada",
	},
	{
		code: "+238",
		name: "Cape Verde",
	},
	{
		code: "+345",
		name: "Cayman Islands",
	},
	{
		code: "+236",
		name: "Central African Republic",
	},
	{
		code: "+235",
		name: "Chad",
	},
	{
		code: "+56",
		name: "Chile",
	},
	{
		code: "+86",
		name: "China",
	},
	{
		code: "+61",
		name: "Christmas Island",
	},
	{
		code: "+61",
		name: "Cocos-Keeling Islands",
	},
	{
		code: "+57",
		name: "Colombia",
	},
	{
		code: "+269",
		name: "Comoros",
	},
	{
		code: "+242",
		name: "Congo",
	},
	{
		code: "+243",
		name: "Congo, Dem. Rep. of (Zaire)",
	},
	{
		code: "+682",
		name: "Cook Islands",
	},
	{
		code: "+506",
		name: "Costa Rica",
	},
	{
		code: "+385",
		name: "Croatia",
	},
	{
		code: "+53",
		name: "Cuba",
	},
	{
		code: "+599",
		name: "Curacao",
	},
	{
		code: "+537",
		name: "Cyprus",
	},
	{
		code: "+420",
		name: "Czech Republic",
	},
	{
		code: "+45",
		name: "Denmark",
	},
	{
		code: "+246",
		name: "Diego Garcia",
	},
	{
		code: "+253",
		name: "Djibouti",
	},
	{
		code: "+1767",
		name: "Dominica",
	},
	{
		code: "+1809",
		name: "Dominican Republic",
	},
	{
		code: "+670",
		name: "East Timor",
	},
	{
		code: "+56",
		name: "Easter Island",
	},
	{
		code: "+593",
		name: "Ecuador",
	},
	{
		code: "+20",
		name: "Egypt",
	},
	{
		code: "+503",
		name: "El Salvador",
	},
	{
		code: "+240",
		name: "Equatorial Guinea",
	},
	{
		code: "+291",
		name: "Eritrea",
	},
	{
		code: "+372",
		name: "Estonia",
	},
	{
		code: "+251",
		name: "Ethiopia",
	},
	{
		code: "+500",
		name: "Falkland Islands",
	},
	{
		code: "+298",
		name: "Faroe Islands",
	},
	{
		code: "+679",
		name: "Fiji",
	},
	{
		code: "+358",
		name: "Finland",
	},
	{
		code: "+33",
		name: "France",
	},
	{
		code: "+596",
		name: "French Antilles",
	},
	{
		code: "+594",
		name: "French Guiana",
	},
	{
		code: "+689",
		name: "French Polynesia",
	},
	{
		code: "+241",
		name: "Gabon",
	},
	{
		code: "+220",
		name: "Gambia",
	},
	{
		code: "+995",
		name: "Georgia",
	},
	{
		code: "+49",
		name: "Germany",
	},
	{
		code: "+233",
		name: "Ghana",
	},
	{
		code: "+350",
		name: "Gibraltar",
	},
	{
		code: "+30",
		name: "Greece",
	},
	{
		code: "+299",
		name: "Greenland",
	},
	{
		code: "+1473",
		name: "Grenada",
	},
	{
		code: "+590",
		name: "Guadeloupe",
	},
	{
		code: "+1671",
		name: "Guam",
	},
	{
		code: "+502",
		name: "Guatemala",
	},
	{
		code: "+224",
		name: "Guinea",
	},
	{
		code: "+245",
		name: "Guinea-Bissau",
	},
	{
		code: "+595",
		name: "Guyana",
	},
	{
		code: "+509",
		name: "Haiti",
	},
	{
		code: "+504",
		name: "Honduras",
	},
	{
		code: "+852",
		name: "Hong Kong SAR China",
	},
	{
		code: "+36",
		name: "Hungary",
	},
	{
		code: "+354",
		name: "Iceland",
	},
	{
		code: "+91",
		name: "India",
	},
	{
		code: "+62",
		name: "Indonesia",
	},
	{
		code: "+98",
		name: "Iran",
	},
	{
		code: "+964",
		name: "Iraq",
	},
	{
		code: "+353",
		name: "Ireland",
	},
	{
		code: "+972",
		name: "Israel",
	},
	{
		code: "+39",
		name: "Italy",
	},
	{
		code: "+225",
		name: "Ivory Coast",
	},
	{
		code: "+1876",
		name: "Jamaica",
	},
	{
		code: "+81",
		name: "Japan",
	},
	{
		code: "+962",
		name: "Jordan",
	},
	{
		code: "+77",
		name: "Kazakhstan",
	},
	{
		code: "+254",
		name: "Kenya",
	},
	{
		code: "+686",
		name: "Kiribati",
	},
	{
		code: "+965",
		name: "Kuwait",
	},
	{
		code: "+996",
		name: "Kyrgyzstan",
	},
	{
		code: "+856",
		name: "Laos",
	},
	{
		code: "+371",
		name: "Latvia",
	},
	{
		code: "+961",
		name: "Lebanon",
	},
	{
		code: "+266",
		name: "Lesotho",
	},
	{
		code: "+231",
		name: "Liberia",
	},
	{
		code: "+218",
		name: "Libya",
	},
	{
		code: "+423",
		name: "Liechtenstein",
	},
	{
		code: "+370",
		name: "Lithuania",
	},
	{
		code: "+352",
		name: "Luxembourg",
	},
	{
		code: "+853",
		name: "Macau SAR China",
	},
	{
		code: "+389",
		name: "Macedonia",
	},
	{
		code: "+261",
		name: "Madagascar",
	},
	{
		code: "+265",
		name: "Malawi",
	},
	{
		code: "+60",
		name: "Malaysia",
	},
	{
		code: "+960",
		name: "Maldives",
	},
	{
		code: "+223",
		name: "Mali",
	},
	{
		code: "+356",
		name: "Malta",
	},
	{
		code: "+692",
		name: "Marshall Islands",
	},
	{
		code: "+596",
		name: "Martinique",
	},
	{
		code: "+222",
		name: "Mauritania",
	},
	{
		code: "+230",
		name: "Mauritius",
	},
	{
		code: "+262",
		name: "Mayotte",
	},
	{
		code: "+52",
		name: "Mexico",
	},
	{
		code: "+691",
		name: "Micronesia",
	},
	{
		code: "+1 808",
		name: "Midway Island",
	},
	{
		code: "+373",
		name: "Moldova",
	},
	{
		code: "+377",
		name: "Monaco",
	},
	{
		code: "+976",
		name: "Mongolia",
	},
	{
		code: "+382",
		name: "Montenegro",
	},
	{
		code: "+1664",
		name: "Montserrat",
	},
	{
		code: "+212",
		name: "Morocco",
	},
	{
		code: "+95",
		name: "Myanmar",
	},
	{
		code: "+264",
		name: "Namibia",
	},
	{
		code: "+674",
		name: "Nauru",
	},
	{
		code: "+977",
		name: "Nepal",
	},
	{
		code: "+31",
		name: "Netherlands",
	},
	{
		code: "+599",
		name: "Netherlands Antilles",
	},
	{
		code: "+1869",
		name: "Nevis",
	},
	{
		code: "+687",
		name: "New Caledonia",
	},
	{
		code: "+64",
		name: "New Zealand",
	},
	{
		code: "+505",
		name: "Nicaragua",
	},
	{
		code: "+227",
		name: "Niger",
	},
	{
		code: "+234",
		name: "Nigeria",
	},
	{
		code: "+683",
		name: "Niue",
	},
	{
		code: "+672",
		name: "Norfolk Island",
	},
	{
		code: "+850",
		name: "North Korea",
	},
	{
		code: "+1 670",
		name: "Northern Mariana Islands",
	},
	{
		code: "+47",
		name: "Norway",
	},
	{
		code: "+968",
		name: "Oman",
	},
	{
		code: "+92",
		name: "Pakistan",
	},
	{
		code: "+680",
		name: "Palau",
	},
	{
		code: "+970",
		name: "Palestinian Territory",
	},
	{
		code: "+507",
		name: "Panama",
	},
	{
		code: "+675",
		name: "Papua New Guinea",
	},
	{
		code: "+595",
		name: "Paraguay",
	},
	{
		code: "+51",
		name: "Peru",
	},
	{
		code: "+63",
		name: "Philippines",
	},
	{
		code: "+48",
		name: "Poland",
	},
	{
		code: "+351",
		name: "Portugal",
	},
	{
		code: "+1787",
		name: "Puerto Rico",
	},
	{
		code: "+974",
		name: "Qatar",
	},
	{
		code: "+262",
		name: "Reunion",
	},
	{
		code: "+40",
		name: "Romania",
	},
	{
		code: "+7",
		name: "Russia",
	},
	{
		code: "+250",
		name: "Rwanda",
	},
	{
		code: "+685",
		name: "Samoa",
	},
	{
		code: "+378",
		name: "San Marino",
	},
	{
		code: "+966",
		name: "Saudi Arabia",
	},
	{
		code: "+221",
		name: "Senegal",
	},
	{
		code: "+381",
		name: "Serbia",
	},
	{
		code: "+248",
		name: "Seychelles",
	},
	{
		code: "+232",
		name: "Sierra Leone",
	},
	{
		code: "+65",
		name: "Singapore",
	},
	{
		code: "+421",
		name: "Slovakia",
	},
	{
		code: "+386",
		name: "Slovenia",
	},
	{
		code: "+677",
		name: "Solomon Islands",
	},
	{
		code: "+27",
		name: "South Africa",
	},
	{
		code: "+500",
		name: "South Georgia and the South Sandwich Islands",
	},
	{
		code: "+82",
		name: "South Korea",
	},
	{
		code: "+34",
		name: "Spain",
	},
	{
		code: "+94",
		name: "Sri Lanka",
	},
	{
		code: "+249",
		name: "Sudan",
	},
	{
		code: "+597",
		name: "Suriname",
	},
	{
		code: "+268",
		name: "Swaziland",
	},
	{
		code: "+46",
		name: "Sweden",
	},
	{
		code: "+41",
		name: "Switzerland",
	},
	{
		code: "+963",
		name: "Syria",
	},
	{
		code: "+886",
		name: "Taiwan",
	},
	{
		code: "+992",
		name: "Tajikistan",
	},
	{
		code: "+255",
		name: "Tanzania",
	},
	{
		code: "+66",
		name: "Thailand",
	},
	{
		code: "+670",
		name: "Timor Leste",
	},
	{
		code: "+228",
		name: "Togo",
	},
	{
		code: "+690",
		name: "Tokelau",
	},
	{
		code: "+676",
		name: "Tonga",
	},
	{
		code: "+1 868",
		name: "Trinidad and Tobago",
	},
	{
		code: "+216",
		name: "Tunisia",
	},
	{
		code: "+90",
		name: "Turkey",
	},
	{
		code: "+993",
		name: "Turkmenistan",
	},
	{
		code: "+1 649",
		name: "Turks and Caicos Islands",
	},
	{
		code: "+688",
		name: "Tuvalu",
	},
	{
		code: "+1 340",
		name: "U.S. Virgin Islands",
	},
	{
		code: "+256",
		name: "Uganda",
	},
	{
		code: "+380",
		name: "Ukraine",
	},
	{
		code: "+971",
		name: "United Arab Emirates",
	},
	{
		code: "+44",
		name: "United Kingdom",
	},
	{
		code: "+1",
		name: "United States",
	},
	{
		code: "+598",
		name: "Uruguay",
	},
	{
		code: "+998",
		name: "Uzbekistan",
	},
	{
		code: "+678",
		name: "Vanuatu",
	},
	{
		code: "+58",
		name: "Venezuela",
	},
	{
		code: "+84",
		name: "Vietnam",
	},
	{
		code: "+1 808",
		name: "Wake Island",
	},
	{
		code: "+681",
		name: "Wallis and Futuna",
	},
	{
		code: "+967",
		name: "Yemen",
	},
	{
		code: "+260",
		name: "Zambia",
	},
	{
		code: "+255",
		name: "Zanzibar",
	},
	{
		code: "+263",
		name: "Zimbabwe",
	},
];

export const directorField = {
	isOpen: true,
	bvn: "",
	idType: "",
	firstName: "",
	lastName: "",
	dob: new Date(),
	idNumber: "",
	title: "",
	occupation: "",
	companyPosition: "",
	gender: "",
	countryResidence: "",
	nationality: "",
	state: "",
	lga: "",
	email: "",
	idCard: "",
	isUserValid: false,
	isSignatory: false,
	isSignatoryDisabled: false,
	lgas: [],
	phoneNumber: "",
	isNigerian: true,
	directors: [],
	maritalStatus: "",
	employmentStatus: "",
	houseNo: "",
	streetName: "",
	zip: "",
	issuedDate: "",
	expiryDate: "",
	companyEmail: "",
	isUsCitizen: false,
	"isBvnVerified": true,
	"isIdVerified": true,
};

export const mandateField = {
	lower_limit: "",
	upper_limit: "",
	authorization_rule: "",
	signatory_rule: "",
	isOpen: true,
};

export const shareholderField = {
	bvn: "",
	isOpen: true,
	idValidated: false,
	shareholderType: { label: "", value: "" },
	idType: { label: "", value: "" },
	dob: new Date(),
	idNumber: "",
	countryCode: { label: "Nigeria (+234)", value: "Nigeria (+234)" },
	phoneNumber: "",
	title: "",
	shareholderName: "",
	email: "",
	dateOfIncorporation: "",
	occupation: { label: "", value: "" },
	nationality: { label: "Nigerian", value: "Nigerian" },
	gender: "",
	businessSector: { label: "", value: "" },
	operatingAddress: "",
	country: { label: "Nigeria", value: "Nigeria" },
	state: { label: "", value: "" },
	lga: { label: "", value: "" },
	lgas: [],
	numberOfShares: 0,
	shareholderSignature: "",
	shareholderIdCard: "",
	rcNumber: "",
	tin: ""
};

export const presenterField = {
	bvn: "",
	isOpen: true,
	idValidated: false,
	presenterType: { label: "", value: "" },
	idType: { label: "", value: "" },
	dob: new Date(),
	idNumber: "",
	countryCode: { label: "Nigeria (+234)", value: "Nigeria (+234)" },
	phoneNumber: "",
	title: "",
	presenterName: "",
	email: "",
	dateOfIncorporation: "",
	nationality: { label: "Nigerian", value: "Nigerian" },
	gender: "",
	businessSector: { label: "", value: "" },
	operatingAddress: "",
	country: { label: "Nigeria", value: "Nigeria" },
	state: { label: "", value: "" },
	lga: { label: "", value: "" },
	lgas: [],
	presenterSignature: "",
	presenterIdCard: "",
	rcNumber: "",
	tin: "",
	id: 0
};

export const businessTypes: any = {
	[BusinessCategory.SoleProprietorship]: "SOL",
	[BusinessCategory.LimitedLiabilityCompany]: "LLC",
	[BusinessCategory.NGOAssociationCooperative]: "NPO",
};


export const businessSectorSubSectors = [
	{
		sector: "ACTIVITIES OF EXTRATERRITORIAL ORGANIZATIONS AND B",
		code: "1000",
		subSectors: [
			{
				label: "ACTIVITIES OF EXTRATERRITORIAL ORGANIZATIONS AND B",
				code: "1001",
			},
		],
	},
	{
		sector: "ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES",
		code: "1100",
		subSectors: [
			{
				label: "RENTAL AND LEASING ACTIVITIES",
				code: "1101",
			},
			{
				label: "EMPLOYMENT ACTIVITIES",
				code: "1102",
			},
			{
				label: "TRAVEL AGENCY, TOUR OPERATOR, RESERVATION SERVICE",
				code: "1103",
			},
			{
				label: "SECURITY AND INVESTIGATION ACTIVITIES",
				code: "1104",
			},
			{
				label: "SERVICES TO BUILDING AND LANDSCAPE ACTIVITIES",
				code: "1105",
			},
			{
				label: "OFFICES ADMINISTRATIVE, OFFICE SUPPORT AND OTHER B",
				code: "1106",
			},
		],
	},
	{
		sector: "AGRICULTURE, FORESTRY AND FISHING",
		code: "1200",
		subSectors: [
			{
				label: "CROP PRODUCTION",
				code: "1201",
			},
			{
				label: "POULTRY AND LIVESTOCK",
				code: "1202",
			},
			{
				label: "FISHING",
				code: "1203",
			},
			{
				label: "PLANTATION",
				code: "1204",
			},
			{
				label: "AGRO SERVICES",
				code: "1205",
			},
			{
				label: "CREATIVE, ARTS AND ENTERTAINMENT ACTIVITIES",
				code: "1206",
			},
		],
	},

	{
		sector: "ARTS, ENTERTAINMENT AND RECREATION",
		code: "1300",
		subSectors: [
			{
				label: "CREATIVE, ARTS AND ENTERTAINMENT ACTIVITIES",
				code: "1301",
			},
			{
				label: "LIBRARIES, ARCHIVES, MUSEUMS AND OTHER CULTURAL AC",
				code: "1302",
			},
			{
				label: "GAMBLING AND BETTING ACTIVITIES",
				code: "1303",
			},
		],
	},
	{
		sector: "CAPITAL MARKET",
		code: "1400",
		subSectors: [
			{
				label: "MARGIN LENDING",
				code: "1401",
			},
			{
				label: "PROPRIETARY TRADING",
				code: "1402",
			},
			{
				label: "SHARE LOANS",
				code: "1403",
			},
			{
				label: "SHARE UNDERWRITING",
				code: "1404",
			},
			{
				label: "BONDS/ DEBT OBLIGATIONS",
				code: "1405",
			},
		],
	},
	{
		sector: "CONSTRUCTION",
		code: "1500",
		subSectors: [
			{
				label: "CIVIL ENGINEERING",
				code: "1501",
			},
			{
				label: "SPECIALIZED CONSTRUCTION ACTIVITIES",
				code: "1502",
			},
		],
	},
	{
		sector: "EDUCATION",
		code: "1600",
		subSectors: [
			{
				label: "PRE PRIMARY AND PRIMARY EDUCATION",
				code: "1601",
			},
			{
				label: "POST PRIMARY EDUCATION",
				code: "1602",
			},
			{
				label: "TERTIARY EDUCATION",
				code: "1603",
			},
			{
				label: "OTHER EDUCATION",
				code: "1604",
			},
			{
				label: "EDUCATIONAL SUPPORT SERVICES",
				code: "1605",
			},
		],
	},
	{
		sector: "FINANCE AND INSURANCE",
		code: "1700",
		subSectors: [
			{
				label: " COMMERCIAL BANK",
				code: "1701",
			},
			{
				label: "MICROFINANCE BANK",
				code: "1702",
			},
			{
				label: "ASSET MANAGEMENT",
				code: "1703",
			},
			{
				label: "MORTGAGE INSTITUTIONS",
				code: "1704",
			},
			{
				label: "INSURANCE COMPANIES",
				code: "1705",
			},
			{
				label: "PENSION FUND CUSTODIANS",
				code: "1706",
			},
			{
				label: "PENSION FUND ADMINISTRATORS",
				code: "1707",
			},
			{
				label: "STOCK BROKING FIRMS",
				code: "1708",
			},
			{
				label: "OTHER FINANCIAL INSTITUTIONS",
				code: "1709",
			},
		],
	},
	{
		sector: "GENERAL (NGO & NPO)",
		code: "1800",
		subSectors: [
			{
				label: " HOTEL AND LEISURE",
				code: "1801",
			},
			{
				label: "PERSONAL",
				code: "1802",
			},
			{
				label: "RELIGIOUS BODIES",
				code: "1803",
			},
			{
				label: "RETAIL (OTHERS)",
				code: "1804",
			},
			{
				label: "LOGISTICS",
				code: "1805",
			},
			{
				label: "NON GOVERNMENT ORGANISATION",
				code: "1806",
			},
			{
				label: "PERSONAL",
				code: "1807",
			},
			{
				label: "STAFF",
				code: "1808",
			},
		],
	},
	{
		sector: "STAFF",
		code: "1801",
		subSectors: [
			{
				label: "STAFF",
				code: "1821",
			},
		],
	},
	{
		sector: "INDIVIDUAL",
		code: "1802",
		subSectors: [
			{
				label: "INDIVIDUAL",
				code: "1811",
			},
		],
	},
	{
		sector: "GENERAL COMMERCE",
		code: "1900",
		subSectors: [
			{
				label: "AUTOMOTIVE PARTS",
				code: "1901",
			},
			{
				label: "DOMESTIC TRADE",
				code: "1902",
			},
			{
				label: "AUTOMOBILE MOTOR VEHICLES",
				code: "1903",
			},
			{
				label: "FOOD PROCESSING",
				code: "1904",
			},
			{
				label: "CHEMICALS AND ALLIED PRODUCTS",
				code: "1905",
			},
			{
				label: "EXPORTS",
				code: "1906",
			},
			{
				label: "TRADING (RICE)",
				code: "1907",
			},
			{
				label: "TRADING (COCOA)",
				code: "1908",
			},
			{
				label: "GENERATOR SET (SALES AND SERVICES)",
				code: "1909",
			},
		],
	},
	{
		sector: "GOVERNMENT",
		code: "2000",
		subSectors: [
			{
				label: "FEDERAL (DIRECT)",
				code: "2001",
			},
			{
				label: "FEDERAL (PARASTATAL)",
				code: "2002",
			},
			{
				label: "STATE (DIRECT)",
				code: "2003",
			},
			{
				label: "STATE (PARASTATAL)",
				code: "2004",
			},
			{
				label: "LOCAL (DIRECT)",
				code: "2005",
			},
			{
				label: "LOCAL (PARASTATAL)",
				code: "2006",
			},
		],
	},
	{
		sector: "HUMAN HEALTH AND SOCIAL WORK ACTIVITIES",
		code: "2100",
		subSectors: [
			{
				label: "HUMAN HEALTH ACTIVITIES",
				code: "2101",
			},
			{
				label: "RESIDENTIAL CARE ACTIVITIES",
				code: "2102",
			},
			{
				label: "SOCIAL WORK ACTIVITIES WITHOUT ACCOMODATION",
				code: "2103",
			},
		],
	},
	{
		sector: "INFORMATION AND COMMUNICATION",
		code: "2200",
		subSectors: [
			{
				label: "PUBLISHING ACTIVITIES",
				code: "2201",
			},
			{
				label: "MOTION PICTURE, VIDEO AND TELEVISION PROGRAMME PRO",
				code: "2202",
			},
			{
				label: "PROGRAMMING AND BROADCASTING ACTIVITIES",
				code: "2203",
			},
			{
				label: "TELECOMMUNICATIONS",
				code: "2204",
			},
			{
				label: "COMPUTER PROGRAMMING, CONSULTANCY AND RELATED ACTI",
				code: "2205",
			},
			{
				label: "INFORMATION SERVICE ACTIVITIES",
				code: "2206",
			},
		],
	},
	{
		sector: "MANUFACTURING",
		code: "2300",
		subSectors: [
			{
				label: "FLOURMILLS AND BAKERIES",
				code: "2301",
			},
			{
				label: "FOOD MANUFACTURING",
				code: "2302",
			},
			{
				label: "BEVERAGES",
				code: "2303",
			},
			{
				label: "ALUMINIUN AND ALLIED PRODUCTS",
				code: "2304",
			},
			{
				label: "BASIC METAL PRODUCTS",
				code: "2305",
			},
			{
				label: "vBREWERIES",
				code: "2306",
			},
			{
				label: " BUILDING MATERIALS",
				code: "2307",
			},
			{
				label: "CEMENT",
				code: "2308",
			},
			{
				label: "CHEMICALS AND ALLIED PRODUCTS",
				code: "2309",
			},
			{
				label: "FOOTWEAR",
				code: "2310",
			},
			{
				label: "HIDES AND SKIN",
				code: "2311",
			},
			{
				label: "HOUSEHOLD EQUIPMENT",
				code: "2312",
			},
			{
				label: "PHARMACEUTICALS",
				code: "2313",
			},
			{
				label: " PAINTS AND ALLIED PRODUCTS",
				code: "2314",
			},
			{
				label: "PAPER AND PAPER PRODUCTS",
				code: "2315",
			},
			{
				label: "PRINTING AND PUBLISHING",
				code: "2316",
			},

			{
				label: "PERSONAL CARE",
				code: "2317",
			},
			{
				label: "PLASTICS",
				code: "2318",
			},
			{
				label: "RUBBER AND ALLIED PORDUCTS",
				code: "2319",
			},
			{
				label: "STEEL ROLLING",
				code: "2320",
			},
			{
				label: "SOFT DRINKS",
				code: "2321",
			},
			{
				label: "CABLES AND MINES",
				code: "2322",
			},
			{
				label: "TEXTILE AND APPAREL",
				code: "2323",
			},
			{
				label: "TYRE",
				code: "2324",
			},
			{
				label: "CONGLOMERATES",
				code: "2325",
			},
		],
	},
	{
		sector: "OIL AND GAS",
		code: "2400",
		subSectors: [
			{
				label: "OIL - DOWNSTREAM - TRADING",
				code: "2401",
			},
			{
				label: "OIL - DOWNSTREAM - MARKETING",
				code: "2402",
			},
			{
				label: "OIL - UPSTREAM",
				code: "2403",
			},
			{
				label: "NATURAL GAS",
				code: "2404",
			},
			{
				label: "CRUDE OIL REFINING",
				code: "2405",
			},
			{
				label: "OIL AND GAS SERVICES",
				code: "2406",
			},
		],
	},
	{
		sector: "POWER AND ENERGY",
		code: "2500",
		subSectors: [
			{
				label: "INDEPENDENT POWER PROJECTS",
				code: "2501",
			},
			{
				label: "POWER GENERATION/POWER PLANTS",
				code: "2502",
			},
			{
				label: "POWER TRANSMISSION",
				code: "2503",
			},
			{
				label: "POWER DISTRIBUTION",
				code: "2504",
			},
		],
	},
	{
		sector: "PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES",
		code: "2600",
		subSectors: [
			{
				label: "LEGAL AND ACCOUNTING ACTIVITIES",
				code: "2601",
			},
			{
				label: "ESTATE AGENT AND VALUERS",
				code: "2602",
			},
			{
				label: "ACTIVITIES OF HEAD OFFICES, MANAGEMENT CONSULTANCY",
				code: "2603",
			},
			{
				label: "ARCHITECTURAL AND ENGINEERING ACTIVITIES; TECHNICA",
				code: "2604",
			},
			{
				label: "SCIENTIFIC RESEARCH AND DEVELOPMENT",
				code: "2605",
			},
			{
				label: "ADVERTISING AND MARKET RESEARCH",
				code: "2606",
			},
			{
				label: "OTHER PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIV",
				code: "2607",
			},
			{
				label: "VETERNARY ACTIVITIES",
				code: "2608",
			},
		],
	},
	{
		sector: "PUBLIC UTILITIES",
		code: "2700",
		subSectors: [
			{
				label: "UTILITY (PUBLIC)",
				code: "2701",
			},
			{
				label: "UTILITY (PRIVATE)",
				code: "2702",
			},
		],
	},
	{
		sector: "REAL ESTATE ACTIVITIES",
		code: "2800",
		subSectors: [
			{
				label: "RESIDENTIAL MORTGAGE LOANS",
				code: "2801",
			},
			{
				label: "COMMERCIAL PROPERTY",
				code: "2802",
			},
			{
				label: "HOME EQUITY",
				code: "2803",
			},
			{
				label: "REAL ESTATE CONSTRUCTION/ HOME DEVELOPERS",
				code: "2804",
			},
			{
				label: "REAL ESTATE (INCOME PRODUCING)",
				code: "2805",
			},
			{
				label: "HIGH VOLATILITY COMMERCIAL REAL ESTATE",
				code: "2806",
			},
		],
	},
	{
		sector: "TRANSPORTATION AND STORAGE",
		code: "2900",
		subSectors: [
			{
				label: "ROAD TRANSPORT",
				code: "2901",
			},
			{
				label: "WATER TRANSPORT",
				code: "2902",
			},
			{
				label: "AIR TRANSPORT",
				code: "2903",
			},
			{
				label: "WAREHOUSING AND SUPPORT ACTIVITIES FOR TRANSPORTAT",
				code: "2904",
			},
			{
				label: "POSTAL AND COURIER ACTIVITIES",
				code: "2905",
			},
			{
				label: "RAIL TRANSPORT",
				code: "2906",
			},
		],
	},
	{
		sector: "WATER SUPPLY; SEWAGE, WASTE MANAGEMENT AND REMEDIA",
		code: "3000",
		subSectors: [
			{
				label: "WATER COLLECTION, TREATMENT AND SUPPLY",
				code: "3001",
			},
			{
				label: "SEWERAGE",
				code: "3002",
			},
			{
				label: "WASTE COLLECTION, TREATMENT AND DISPOSAL ACTIVITIE",
				code: "3003",
			},
			{
				label: "REMEDIATION ACTIVITIES AND OTHER WASTE MANAGEMENT",
				code: "3004",
			},
		],
	},
	{
		sector: "ONLINE ACCOUNT OPENING",
		code: "OAO",
		subSectors: [
			{
				label: "ONLINE ACCOUNT OPENING",
				code: "OAO",
			},
		],
	},
	{
		sector: "MINING AND QUARYING",
		code: "40200",
		subSectors: [
			{
				label: "COAL",
				code: "40210",
			},
			{
				label: "METALIC MINING (TIN| ORE IRON| CINC AND LEAD)",
				code: "40220",
			},
			{
				label: "CRUDE PETROLEUM AND GAS",
				code: "40230",
			},
			{
				label: "OTHER NON-METALLIC MINING",
				code: "40240",
			},
		],
	},
	{
		sector: "REAL ESTATE AND CONSTRUCTION",
		code: "40500",
		subSectors: [
			{
				label: "OWNER OCCUPIED PROPERTY",
				code: "40510",
			},
			{
				label: "COMMERCIAL PROPERTY",
				code: "40520",
			},
			{
				label: "RESIDENTIAL",
				code: "40521",
			},
			{
				label: "NON-RESIDENTIAL",
				code: "40522",
			},
			{
				label: "PUBLIC CONSTRUCTION",
				code: "40530",
			},
			{
				label: "OTHERS-MINING AND QUARRYING, REAL ESTATE",
				code: "40540",
			},
		],
	},
	{
		sector: "PUBLIC UTILITIES",
		code: "40600",
		subSectors: [
			{
				label: "PUBLIC UTILITIES",
				code: "40600",
			},
			{
				label: "ELECTRICITY, WATER, HARBOUR ETC",
				code: "40610",
			},
		],
	},
	{
		sector: "GENERAL COMMERCE",
		code: "40700",
		subSectors: [
			{
				label: "EXPORTS",
				code: "40701",
			},
			{
				label: "IMPORT AND DOMESTIC TRADE",
				code: "40702",
			},
			{
				label: "COCOA",
				code: "40710",
			},
			{
				label: "GROUNDNUTS, OIL SEEDS PALM PRODUCE",
				code: "40720",
			},
			{
				label: "PALM PRODUCE",
				code: "40730",
			},
			{
				label: "COTTON",
				code: "40740",
			},
			{
				label: "HIDES AND SKIN",
				code: "40750",
			},
			{
				label: "RUBBER AND PRODUCTS, TOBACCO",
				code: "40760",
			},
			{
				label: "TIMBER AND PRODUCTS ",
				code: "40770",
			},
			{
				label: "NON-AGRICULTURAL EXPORTS",
				code: "40790",
			},
			{
				label: "IMPORTS",
				code: "40800",
			},
			{
				label: "DOMESTIC TRADE",
				code: "40810",
			},
			{
				label: "WHOLESALE MERCHANTS",
				code: "40811",
			},
			{
				label: "RETAIL MERCHANTS",
				code: "40812",
			},
		],
	},
	{
		sector: "TRANSPORTATION AND COMMUNICATION",
		code: "40900",
		subSectors: [
			{
				label: "RAIL TRANSPORT",
				code: "40910",
			},
			{
				label: "ROAD TRANSPORT",
				code: "40920",
			},
			{
				label: " WATER TRANSPORT",
				code: "40930",
			},
			{
				label: "AIR TRANSPORT",
				code: "40940",
			},
			{
				label: "OTHER COMMUNICATIONS",
				code: "40950",
			},
		],
	},
	{
		sector: "FINANCE AND INSURANCE",
		code: "41000",
		subSectors: [
			{
				label: "COMMERCIAL BANKS",
				code: "41010",
			},
			{
				label: "MERCHANT BANKS",
				code: "41020",
			},
			{
				label: "FINANCE COMPANIES",
				code: "41030",
			},
			{
				label: "MORTGAGE INSTITUTIONS",
				code: "41040",
			},
			{
				label: "OTHER BANKING INSTITUTIONS",
				code: "41050",
			},
			{
				label: "INSURANCE COMPANIES",
				code: "41060",
			},
		],
	},
	{
		sector: "GENERAL",
		code: "41100",
		subSectors: [
			{
				label: "PERSONAL AND PROFESSIONAL",
				code: "41010",
			},
			{
				label: "HOTEL AND TOURISM",
				code: "41020",
			},
			{
				label: "MISCELLANEOUS ",
				code: "41030",
			},
		],
	},
	{
		sector: "GOVERNMENT",
		code: "41200",
		subSectors: [
			{
				label: "FEDERAL",
				code: "41210",
			},
			{
				label: "STATE",
				code: "41220",
			},
			{
				label: "LOCAL",
				code: "41230",
			},
		],
	},
	{
		sector: "OTHERS",
		code: "41300",
		subSectors: [
			{
				label: "NOT APPLICABLE",
				code: "41300",
			},
		],
	},
	{
		sector: "AGRICULTURE",
		code: "40100",
		subSectors: [
			{
				label: "OTHER LIVESTOCK",
				code: "40150",
			},
			{
				label: "FORESTRY",
				code: "40160",
			},
			{
				label: "FISHING",
				code: "40170",
			},
			{
				label: "OTHER AGRICULTURAL PRODUCTS",
				code: "40180",
			},
			{
				label: "FOOD CROPS (GRAINS)",
				code: "40110",
			},
			{
				label: "FOOD CROPS - PRODUCTION",
				code: "40111",
			},
			{
				label: "OTHER FOOD CROPS",
				code: "40120",
			},
			{
				label: "CASH CROPS",
				code: "40130",
			},
		],
	},
];

export const states = [
	{
		stateCode: "LG",
		state: "lagos",
		lgas: [
			{
				lgaCode: "LAG",
				lga: "LAGOS ISLAND",
			},
			{
				lgaCode: "APP",
				lga: "APAPA",
			},
			{
				lgaCode: "IKJ",
				lga: "IKEJA",
			},
			{
				lgaCode: "VIL",
				lga: "VICTORIA ISLAND",
			},
			{
				lgaCode: "OJO",
				lga: "OJO",
			},
			{
				lgaCode: "IKY",
				lga: "IKOYI",
			},
			{
				lgaCode: "ALM",
				lga: "ALIMOSHO",
			},
			{
				lgaCode: "AJA",
				lga: "AJAH",
			},
			{
				lgaCode: "OSS",
				lga: "OSHODIISOLO",
			},
			{
				lgaCode: "IKD",
				lga: "IKORODU",
			},
			{
				lgaCode: "MUS",
				lga: "MUSHIN",
			},
			{
				lgaCode: "SLR",
				lga: "SURULERE",
			},
			{
				lgaCode: "ETS",
				lga: "ETIOSA",
			},
			{
				lgaCode: "ISL",
				lga: "ISOLO",
			},
			{
				lgaCode: "KSF",
				lga: "KOSOFE",
			},
			{
				lgaCode: "AGG",
				lga: "AGEGE",
			},
			{
				lgaCode: "AJR",
				lga: "AJEROMI/IFELODUN",
			},
			{
				lgaCode: "SMK",
				lga: "SHOMOLU",
			},
			{
				lgaCode: "AMW",
				lga: "AMUWO ODOFIN",
			},
			{
				lgaCode: "LAG2",
				lga: "LAGOS MAINLAND",
			},
		],
		branches: [
			{
				branch: "HEAD OFFICE BRANCH",
				sol: "001",
			},
			{
				branch: "APAPA I BRANCH",
				sol: "006",
			},
			{
				branch: "OBA AKRAN I BRANCH",
				sol: "007",
			},
			{
				branch: "IDUMAGBO BRANCH",
				sol: "010",
			},
			{
				branch: "ADEOLA ODEKU BRANCH",
				sol: "023",
			},
			{
				branch: "ALABA I BRANCH",
				sol: "026",
			},
			{
				branch: "AWOLOWO I BRANCH",
				sol: "027",
			},
			{
				branch: "ALAGBADO BRANCH",
				sol: "030",
			},
			{
				branch: "ALLEN I BRANCH",
				sol: "031",
			},
			{
				branch: "OKE-ARIN BRANCH",
				sol: "032",
			},
			{
				branch: "AJAH BRANCH",
				sol: "034",
			},
			{
				branch: "IDUMOTA",
				sol: "037",
			},
			{
				branch: "BROAD STREET BRANCH",
				sol: "039",
			},
			{
				branch: "AIRPORT RD BRANCH",
				sol: "051",
			},
			{
				branch: "APAPA II BRANCH",
				sol: "056",
			},
			{
				branch: "IKORODU BRANCH",
				sol: "061",
			},
			{
				branch: "MOTORWAYS BRANCH",
				sol: "065",
			},
			{
				branch: "OJO BRANCH",
				sol: "066",
			},
			{
				branch: "SANUSI FAFUNWA BRANCH",
				sol: "069",
			},
			{
				branch: " MUSHIN BRANCH",
				sol: "075",
			},
			{
				branch: "AKOWONJO BRANCH",
				sol: "076",
			},
			{
				branch: "ADENIRAN OGUNSANYA BRANCH",
				sol: "079",
			},
			{
				branch: "OGBA BRANCH",
				sol: "081",
			},
			{
				branch: "YABA BRANCH",
				sol: "082",
			},
			{
				branch: "ONIRU BRANCH",
				sol: "083",
			},
			{
				branch: "MATORI BRANCH",
				sol: "087",
			},
			{
				branch: "OKOTA BRANCH",
				sol: "091",
			},
			{
				branch: "LEKKI  BRANCH",
				sol: "095",
			},
			{
				branch: "KETU BRANCH",
				sol: "107",
			},
			{
				branch: "ADEOLA HOPEWELL BRANCH",
				sol: "110",
			},
			{
				branch: "AGEGE BRANCH",
				sol: "114",
			},
			{
				branch: "ORILE COKER BRANCH",
				sol: "118",
			},
			{
				branch: "MACARTHY BRANCH",
				sol: "119",
			},
			{
				branch: "IDIMU BRANCH",
				sol: "129",
			},
			{
				branch: "DETOKUNBO ADEMOLA BRANCH",
				sol: "132",
			},
			{
				branch: "MARINA BRANCH",
				sol: "135",
			},
			{
				branch: "IKEJA GRA BRANCH",
				sol: "137",
			},
			{
				branch: "WHARF",
				sol: "142",
			},
			{
				branch: "KINGSWAY ROAD BRANCH",
				sol: "143",
			},
			{
				branch: "ONIPAN BRANCH",
				sol: "145",
			},
			{
				branch: "DAVIES STREET BRANCH",
				sol: "146",
			},
			{
				branch: "TRADE FAIR II BRANCH",
				sol: "147",
			},
			{
				branch: "OSOLO WAY BRANCH",
				sol: "152",
			},
			{
				branch: "RANDLE RD BRANCH",
				sol: "154",
			},
			{
				branch: "ASPAMDA II BRANCH",
				sol: "155",
			},
			{
				branch: "MOBOLAJI BANK ANTHONY BRANCH",
				sol: "157",
			},
			{
				branch: "IKEJA LOCAL AIRPORT BRANCH",
				sol: "158",
			},
			{
				branch: "OROYINYIN BRANCH",
				sol: "161",
			},
			{
				branch: "ALABA II BRANCH",
				sol: "163",
			},
			{
				branch: "OREGUN BRANCH",
				sol: "164",
			},
			{
				branch: "OSHODI BRANCH",
				sol: "165",
			},
			{
				branch: "JOSEPH STREET BRANCH",
				sol: "167",
			},
			{
				branch: "IDDO BRANCH",
				sol: "169",
			},
			{
				branch: "FESTAC BRANCH",
				sol: "174",
			},
			{
				branch: "LEKKI CHEVRON BRANCH",
				sol: "175",
			},
			{
				branch: "OGUDU BRANCH",
				sol: "176",
			},
			{
				branch: "ILUPEJU BRANCH",
				sol: "181",
			},
			{
				branch: "LEKKI ADMIRALTY WAY BRANCH",
				sol: "280",
			},
			{
				branch: "AMUWO-ODOFIN BRANCH",
				sol: "282",
			},
			{
				branch: "GBAGADA BRANCH",
				sol: "283",
			},
		],
		region: "70j"
	},
	{
		stateCode: "FC",
		state: "federal capital territory",
		lgas: [
			{
				lgaCode: "ABJ",
				lga: "ABUJA",
			},
			{
				lgaCode: "GAR",
				lga: "GARKI",
			},
			{
				lgaCode: "BWA",
				lga: "BWARI",
			},
			{
				lgaCode: "GWG",
				lga: "GWAGWALADA",
			},
			{
				lgaCode: "KUJ",
				lga: "KUJE",
			},
		],
		branches: [
			{
				branch: "ABUJA FIRST CITY PLAZA BRANCH",
				sol: "002",
			},
			{
				branch: "ABUJA AREA 8 BRANCH",
				sol: "044",
			},
			{
				branch: "ABUJA FEDSEC PHASE III BRANCH",
				sol: "073",
			},
			{
				branch: "ABUJA WUSE II BRANCH",
				sol: "111",
			},
			{
				branch: "ABUJA BANEX PLAZA BRANCH",
				sol: "138",
			},
			{
				branch: "ASOKORO BRANCH",
				sol: "182",
			},
			{
				branch: "ABUJA AREA 7 BRANCH",
				sol: "185",
			},
			{
				branch: "ABUJA  ZONE 4 BRANCH",
				sol: "189",
			},
			{
				branch: "ABUJA GARKI II BRANCH",
				sol: "190",
			},
			{
				branch: "ABUJA PORTHACOURT CRESCENT BRANCH",
				sol: "205",
			},
			{
				branch: "JABI BRANCH",
				sol: "206",
			},
			{
				branch: "MAITAMA MEDITERRANEAN BRANCH",
				sol: "207",
			},
			{
				branch: "ABUJA BWARI BRANCH",
				sol: "218",
			},
			{
				branch: "ABUJA AMINU KANO CRESCENT BRANCH",
				sol: "220",
			},
			{
				branch: "ABUJA GUDU BRANCH",
				sol: "222",
			},
			{
				branch: "ABUJA NASS I BRANCH",
				sol: "223",
			},
			{
				branch: "GWAGWALADA BRANCH",
				sol: "225",
			},
			{
				branch: "ABUJA KUJE BRANCH",
				sol: "226",
			},
			{
				branch: "ABUJA CREST PLAZA BRANCH",
				sol: "227",
			},
			{
				branch: "ABUJA KUBWA BRANCH",
				sol: "231",
			},
			{
				branch: "ABUJA GANA STREET BRANCH",
				sol: "233",
			},
		],
		region: "60j"
	},
	{
		stateCode: "RV",
		state: "rivers",
		lgas: [
			{
				lgaCode: "PHC",
				lga: "PORTHARCOURT",
			},
			{
				lgaCode: "OYIG",
				lga: "OYIGBO",
			},
			{
				lgaCode: "OBIA",
				lga: "OBIA/AKPOR",
			},
			{
				lgaCode: "KHA",
				lga: "KHANA",
			},
		],
		branches: [
			{
				branch: "PH MAIN BRANCH",
				sol: "003",
			},
			{
				branch: "GARRISON BRANCH",
				sol: "043",
			},
			{
				branch: "OLU OBASANJO BRANCH",
				sol: "049",
			},
			{
				branch: "IKWERRE I BRANCH",
				sol: "064",
			},
			{
				branch: "OYIGBO BRANCH",
				sol: "108",
			},
			{
				branch: "ABULOMA BRANCH",
				sol: "109",
			},
			{
				branch: "AGGREY BRANCH",
				sol: "126",
			},
			{
				branch: "TRANS AMADI II BRANCH",
				sol: "134",
			},
			{
				branch: "IKWERRE II BRANCH",
				sol: "239",
			},
			{
				branch: "AZIKIWE BRANCH",
				sol: "252",
			},
			{
				branch: "RUMUOMASI BRANCH",
				sol: "253",
			},
			{
				branch: "PH ABA ROAD 2",
				sol: "256",
			},
			{
				branch: "BORI BRANCH",
				sol: "261",
			},
			{
				branch: "TRANS AMADI III BRANCH",
				sol: "268",
			},
			{
				branch: "RUMUOKORO BRANCH",
				sol: "275",
			},
		],
		region: "90j"
	},
	{
		stateCode: "KN",
		state: "kano",
		lgas: [
			{
				lgaCode: "FAG",
				lga: "FAGGE",
			},
		],
		branches: [
			{
				branch: "KANO MAIN BRANCH",
				sol: "004",
			},
			{
				branch: "KANO BELLO ROAD BRANCH",
				sol: "055",
			},
			{
				branch: "KANO MM WAY 1 BRANCH",
				sol: "183",
			},
			{
				branch: "KANO 40 MM WAY  BRANCH",
				sol: "199",
			},
			{
				branch: "KANO IBRAHIM TAIWO BRANCH",
				sol: "200",
			},
			{
				branch: "KANO BOMPAI BRANCH",
				sol: "213",
			},
		],
		region: "60j"
	},
	{
		stateCode: "DL",
		state: "delta",
		lgas: [
			{
				lgaCode: "WAR3",
				lga: "WARRI SOUTH",
			},
			{
				lgaCode: "OSM",
				lga: "OSHIMILI",
			},
			{
				lgaCode: "UVW",
				lga: "UVWIE",
			},
			{
				lgaCode: "UDH1",
				lga: "UGHELLI NORTH",
			},
		],
		branches: [
			{
				branch: "WARRI  I BRANCH",
				sol: "008",
			},
			{
				branch: "ASABA I BRANCH",
				sol: "054",
			},
			{
				branch: "EFFURUN BRANCH",
				sol: "072",
			},
			{
				branch: "WARRI II (AIRPORT RD) BRANCH",
				sol: "234",
			},
			{
				branch: "ASABA NNEBISI ROAD BRANCH",
				sol: "238",
			},
			{
				branch: "UGHELLI BRANCH",
				sol: "257",
			},
		],
		region: "60j"
	},
	{
		stateCode: "ED",
		state: "edo",
		lgas: [
			{
				lgaCode: "ORE",
				lga: "OREDO",
			},
			{
				lgaCode: "EGO",
				lga: "EGOR",
			},
		],
		branches: [
			{
				branch: "MISSION ROAD BRANCH",
				sol: "009",
			},
			{
				branch: "UGBOWO BRANCH",
				sol: "086",
			},
		],
		region: "90j"
	},
	{
		stateCode: "OG",
		state: "ogun",
		lgas: [
			[
				{
					lgaCode: "IJB",
					lga: "IJEBU-ODE",
				},
				{
					lgaCode: "ABE2",
					lga: "ABEOKUTA SOUTH",
				},
				{
					lgaCode: "IJB2",
					lga: "IJEBU NORTH",
				},
				{
					lgaCode: "SGM",
					lga: "SAGAMU",
				},
				{
					lgaCode: "ADOO",
					lga: "ADO-ODO/OTA",
				},
				{
					lgaCode: "IFO",
					lga: "IFO",
				},
				{
					lgaCode: "BFM",
					lga: "OBAFEMI/OWODE",
				},
			],
		],
		branches: [
			"IJEBU-ODE I BRANCH",
			"ABEOKUTA BRANCH",
			"AGO-IWOYE",
			"SAGAMU BRANCH",
			"OTTA BRANCH",
			"AKUTE BRANCH",
			"AGBARA BRANCH",
			"IJEBU-ODE II BRANCH",
			"RCCG CAMP BRANCH",
		],
		region: "80j"
	},
	{
		stateCode: "ON",
		state: "ondo",
		lgas: [
			{
				lgaCode: "OND2",
				lga: "ONDO WEST",
			},
			{
				lgaCode: "IJE",
				lga: "ILAJE",
			},
			{
				lgaCode: "AKR2",
				lga: "AKURE SOUTH",
			},
		],
		branches: [
			{
				branch: "ONDO BRANCH",
				sol: "012",
			},
			{
				branch: "IGBOKODA BRANCH",
				sol: "100",
			},
			{
				branch: "AKURE 1 BRANCH",
				sol: "133",
			},
			{
				branch: "AKURE II BRANCH",
				sol: "279",
			},
		],
		region: "80j"
	},
	{
		stateCode: "AB",
		state: "abia",
		lgas: [
			{
				lgaCode: "ABA2",
				lga: "ABA SOUTH",
			},
			{
				lgaCode: "ABA1",
				lga: "ABA NORTH",
			},
			{
				lgaCode: "UMU1",
				lga: "UMUAHIA NORTH",
			},
		],
		branches: [
			{
				branch: "ASA ROAD I BRANCH",
				sol: "013",
			},
			{
				branch: "FAULKS ROAD BRANCH",
				sol: "045",
			},
			{
				branch: "UMUAHIA I BRANCH",
				sol: "078",
			},
			{
				branch: "BRASS ROAD BRANCH",
				sol: "237",
			},
			{
				branch: "UMUAHIA II BRANCH",
				sol: "267",
			},
		],
		region: "90j"
	},
	{
		stateCode: "AN",
		state: "anambra",
		lgas: [
			{
				lgaCode: "OSH",
				lga: "ONITSHA",
			},
			{
				lgaCode: "NNE",
				lga: "NNEWI",
			},
			{
				lgaCode: "AWK",
				lga: "AWKA",
			},
		],
		branches: [
			{
				branch: "ONITSHA NEW MKT RD I BRANCH",
				sol: "021",
			},
			{
				branch: "OKO BRANCH",
				sol: "067",
			},
			{
				branch: "AWKA BRANCH",
				sol: "094",
			},
			{
				branch: "NNEWI I BRANCH",
				sol: "131",
			},
			{
				branch: "ONITSHA BRIDGEHEAD BRANCH",
				sol: "140",
			},
			{
				branch: "AWKA II BRANCH",
				sol: "243",
			},
			{
				branch: "ONITSHA NEW MARKET RD III BRANCH",
				sol: "248",
			},
			{
				branch: "OBOSI BRANCH",
				sol: "270",
			},
		],
		region: "90j"
	},
	{
		stateCode: "AK",
		state: "akwa ibom",
		lgas: [
			{
				lgaCode: "UYO",
				lga: "UYO",
			},
			{
				lgaCode: "EKE",
				lga: "EKET",
			},
			{
				lgaCode: "IKP",
				lga: "IKOT EKPENE",
			},
		],
		branches: [
			{
				branch: "UYO ABAK ROAD BRANCH",
				sol: "028",
			},
			{
				branch: "EKET BRANCH",
				sol: "040",
			},
			{
				branch: "UYO, ORON BRANCH",
				sol: "042",
			},
			{
				branch: "IKOT EKPENE I BRANCH",
				sol: "063",
			},
		],
		region: "90j"
	},
	{
		stateCode: "OY",
		state: "oyo",
		lgas: [
			{
				lgaCode: "IBD4",
				lga: "IBADAN SOUTH WEST",
			},
			{
				lgaCode: "IBD1",
				lga: "IBADAN NORTH",
			},
			{
				lgaCode: "AKY",
				lga: "AKINYELE",
			},
		],
		branches: [
			{
				branch: "DUGBE BRANCH",
				sol: "029",
			},
			{
				branch: "BODIJA BRANCH",
				sol: "058",
			},
			{
				branch: "UCH BRANCH",
				sol: "077",
			},
			{
				branch: "OJOO BRANCH",
				sol: "122",
			},
			{
				branch: "MOLETE BRANCH",
				sol: "148",
			},
			{
				branch: "AGBENI BRANCH",
				sol: "171",
			},
			{
				branch: "AGBOWO BRANCH",
				sol: "172",
			},
			{
				branch: "IWO ROAD BRANCH",
				sol: "177",
			},
		],
		region: "80j"
	},
	{
		stateCode: "BN",
		state: "benue",
		lgas: [
			{
				lgaCode: "MAK",
				lga: "MAKURDI",
			},
		],
		branches: [
			{
				branch: "MAKURDI BRANCH",
				sol: "035",
			},
		],
		region: "60j"
	},
	{
		stateCode: "CR",
		state: "cross river",
		lgas: [
			{
				lgaCode: "CAL",
				lga: "CALABAR",
			},
			{
				lgaCode: "IKM",
				lga: "IKOM",
			},
			{
				lgaCode: "CAL1",
				lga: "CALABAR MUNCIPALITY",
			},
		],
		branches: [
			{
				branch: "CALABAR BRANCH",
				sol: "038",
			},
			{
				branch: "IKOM BRANCH",
				sol: "060",
			},
			{
				branch: "NEW SECRETARIAT, CALABAR  BRANCH",
				sol: "235",
			},
		],
		region: "90j"
	},
	{
		stateCode: "BY",
		state: "bayelsa",
		lgas: [
			{
				lgaCode: "YEN",
				lga: "YENEGOA",
			},
		],
		branches: [
			{
				branch: "YENAGOA  BRANCH",
				sol: "047",
			},
			{
				branch: "YENAGOA II BRANCH",
				sol: "240",
			},
		],
		region: "90j"
	},
	{
		stateCode: "KD",
		state: "kaduna",
		lgas: [
			{
				lgaCode: "KAD",
				lga: "KADUNA",
			},
			{
				lgaCode: "SBG",
				lga: "SABONGARI",
			},
			{
				lgaCode: "CHK",
				lga: "CHIKUN",
			},
			{
				lgaCode: "KAC",
				lga: "KACHIA",
			},
		],
		branches: [
			{
				branch: "AHMADU BELLO WAY KADUNA BRANCH",
				sol: "048",
			},
			{
				branch: "KACHIA ROAD KADUNA BRANCH",
				sol: "089",
			},
			{
				branch: "ZARIA 1 BRANCH",
				sol: "128",
			},
			{
				branch: "KANO ROAD KADUNA BRANCH",
				sol: "184",
			},
			{
				branch: "SABON TASHA BRANCH",
				sol: "197",
			},
			{
				branch: "ALI AKILU ROAD KADUNA BRANCH",
				sol: "199",
			},
			{
				branch: "KACHIA POLICE BRANCH BRANCH",
				sol: "209",
			},
			{
				branch: "YAKUBU GOWON WAY KADUNA BRANCH",
				sol: "210",
			},
		],
		region: "60j"
	},
	{
		stateCode: "EK",
		state: "ekiti",
		lgas: [
			{
				lgaCode: "EKT",
				lga: "EKITI",
			},
		],
		branches: [
			{
				branch: "ADO-EKITI BRANCH",
				sol: "050",
			},
		],
		region: "80j"
	},
	{
		stateCode: "EN",
		state: "enugu",
		lgas: [
			{
				lgaCode: "KAD",
				lga: "KADUNA",
			},
			{
				lgaCode: "SBG",
				lga: "SABONGARI",
			},
			{
				lgaCode: "CHK",
				lga: "CHIKUN",
			},
			{
				lgaCode: "KAC",
				lga: "KACHIA",
			},
		],
		branches: [
			{
				branch: "ENUGU MKT RD BRANCH",
				sol: "057",
			},
			{
				branch: "GARDEN AVENUE ENUGU BRANCH",
				sol: "084",
			},
			{
				branch: "NSUKKA BRANCH",
				sol: "263",
			},
			{
				branch: "AGBANI TOWN BRANCH",
				sol: "265",
			},
			{
				branch: "PRESIDENTIAL ROAD BRANCH",
				sol: "278",
			},
		],
		region: "90j"
	},
	{
		stateCode: "OS",
		state: "Osun",
		lgas: [
			{
				lgaCode: "OSG",
				lga: "OSHOGBO",
			},
			{
				lgaCode: "ILE1",
				lga: "ILESHA EAST",
			},
			{
				lgaCode: "IFE1",
				lga: "IFE CENTRAL",
			},
		],
		branches: [
			{
				branch: "OSOGBO I BRANCH",
				sol: "068",
			},
			{
				branch: "ILESHA BRANCH",
				sol: "105",
			},
			{
				branch: "ILE- IFE BRANCH",
				sol: "286",
			},
		],
		region: "80j"
	},
	{
		stateCode: "NI",
		state: "niger",
		lgas: [
			{
				lgaCode: "CHC",
				lga: "CHANCHAGA",
			},
			{
				lgaCode: "SLJ",
				lga: "SULEJA",
			},
		],
		branches: [
			{
				branch: "MINNA 1 BRANCH",
				sol: "074",
			},
			{
				branch: "SULEJA BRANCH",
				sol: "232",
			},
		],
		region: "60j"
	},
	{
		stateCode: "BO",
		state: "borno",
		lgas: [
			{
				lgaCode: "BNO",
				lga: "BORNO",
			},
		],
		branches: [
			{
				branch: "MAIDUGURI I BRANCH",
				sol: "080",
			},
		],
		region: "60j"
	},
	{
		stateCode: "IM",
		state: "imo",
		lgas: [
			{
				lgaCode: "OWE3",
				lga: "OWERRI MUNICIPAL",
			},
			{
				lgaCode: "ORL",
				lga: "ORLU",
			},
		],
		branches: [
			{
				branch: "WETHERAL ROAD I BRANCH",
				sol: "085",
			},
			{
				branch: "WETHERAL ROAD II BRANCH",
				sol: "241",
			},
			{
				branch: "ORLU II BRANCH",
				sol: "244",
			},
			{
				branch: "MBAISE RD OWERRI BRANCH",
				sol: "251",
			},
		],
		region: "90j"
	},
	{
		stateCode: "PL",
		state: "plateau",
		lgas: [
			{
				lgaCode: "JOS2",
				lga: "JOSNORTH",
			},
		],
		branches: [
			{
				branch: "BRITISH AMERICAN JUNCTION JOS",
				sol: "088",
			},
			{
				branch: "BEACH ROAD JOS BRANCH",
				sol: "186",
			},
			{
				branch: "MURTALA MOHAMMED WAY JOS BRANCH",
				sol: "203",
			},
		],
		region: "60j"
	},
	{
		stateCode: "GM",
		state: "gombe",
		lgas: [
			{
				lgaCode: "GOM",
				lga: "GOMBE",
			},
			{
				lgaCode: "FKY",
				lga: "FUNAKAYE",
			},
		],
		branches: [
			{
				branch: "GOMBE 1 BRANCH",
				sol: "092",
			},
			{
				branch: "ASHAKA BRANCH",
				sol: "130",
			},
		],
		region: "60j"
	},
	{
		stateCode: "AD",
		state: "adamawa",
		lgas: [
			{
				lgaCode: "ADM",
				lga: "ADAMAWA",
			},
		],
		branches: [
			{
				branch: "YOLA BRANCH",
				sol: "093",
			},
		],
		region: "60j"
	},
	{
		stateCode: "KT",
		state: "katsina",
		lgas: [
			{
				lgaCode: "KAT",
				lga: "KATSINA ALA",
			},
		],
		branches: [
			{
				branch: "MAKURDI BRANCH",
				sol: "035",
			},
		],
		region: "60j"
	},
	{
		stateCode: "BA",
		state: "bauchi",
		lgas: [
			{
				lgaCode: "BAU",
				lga: "BAUCHI",
			},
			{
				lgaCode: "KATG",
				lga: "KATAGUM",
			},
		],
		branches: [
			{
				branch: "COMMERCIAL RD BAUCHI BRANCH",
				sol: "097",
			},
			{
				branch: "AZARE BRANCH",
				sol: "191",
			},
			{
				branch: "BAUCHI JOS ROAD",
				sol: "192",
			},
		],
		region: "60j"
	},
	{
		stateCode: "JG",
		state: "jigawa",
		lgas: [
			{
				lgaCode: "DUT",
				lga: "DUTSE",
			},
		],
		branches: [
			{
				branch: "MISSION ROAD BRANCH",
				sol: "009",
			},
			{
				branch: "UGBOWO BRANCH",
				sol: "086",
			},
			{
				branch: "DUTSE 1 BRANCH",
				sol: "101",
			},
		],
		region: "60j"
	},
	{
		stateCode: "KG",
		state: "kogi",
		lgas: [
			{
				lgaCode: "LOK",
				lga: "LOKOJA",
			},
			{
				lgaCode: "DEK",
				lga: "DEKINA",
			},
		],
		branches: [
			{
				branch: "LOKOJA 1 BRANCH",
				sol: "102",
			},
			{
				branch: "AYINGBA BRANCH",
				sol: "224",
			},
		],
		region: "60j"
	},
	{
		stateCode: "EB",
		state: "ebonyi",
		lgas: [
			{
				lgaCode: "ABL",
				lga: "ABAKALIKI",
			},
		],
		branches: [
			{
				branch: "ABAKALIKI BRANCH",
				sol: "112",
			},
		],
		region: "90j"
	},
	{
		stateCode: "TR",
		state: "taraba",
		lgas: [
			{
				lgaCode: "JAL",
				lga: "JALINGO",
			},
		],
		branches: [
			{
				branch: "JALINGO BRANCH",
				sol: "116",
			},
		],
		region: "60j"
	},
	{
		stateCode: "YB",
		state: "yobe",
		lgas: [
			{
				lgaCode: "DBT",
				lga: "DAMATURU",
			},
		],
		branches: [
			{
				branch: "DAMATURU 1 BRANCH",
				sol: "117",
			},
		],
		region: "60j"
	},
	{
		stateCode: "KW",
		state: "kwara",
		lgas: [
			{
				lgaCode: "ILO1",
				lga: "ILORIN EAST",
			},
			{
				lgaCode: "ILO2",
				lga: "ILORIN WEST",
			},
		],
		branches: [
			{
				branch: "MURTALA MOHAMMED ROAD  BRANCH",
				sol: "121",
			},
			{
				branch: "IBRAHIM TAIWO RD BRANCH",
				sol: "151",
			},
			{
				branch: "ABDULAZEEZ ATTAH, ILORIN BRANCH",
				sol: "152",
			},
		],
		region: "80j"
	},
	{
		stateCode: "ZM",
		state: "zamfara",
		lgas: [
			{
				lgaCode: "GUS",
				lga: "GUSAU",
			},
		],
		branches: [
			{
				branch: "GUSAU 1 BRANCH",
				sol: "125",
			},
		],
		region: "60j"
	},
	{
		stateCode: "SK",
		state: "sokoto",
		lgas: [
			{
				lgaCode: "SKT",
				lga: "SOKOTO",
			},
		],
		branches: [
			{
				branch: "SOKOTO BRANCH",
				sol: "127",
			},
		],
		region: "60j"
	},
	{
		stateCode: "KB",
		state: "kebbi",
		lgas: [
			{
				lgaCode: "BIRNIN KEBBI",
			},
		],
		branches: [
			{
				branch: "KEBBI II BRANCH",
				sol: "201",
			},
		],
		region: "60j"
	},
	{
		stateCode: "NS",
		state: "nasarawa",
		lgas: [
			{
				lgaCode: "LAF",
				lga: "LAFIA",
			},
			{
				lgaCode: "KEF",
				lga: "KEFFI",
			},
			{
				lgaCode: "KAR",
				lga: "KARU",
			},
		],
		branches: [
			{
				branch: "LAFIA II BRANCH",
				sol: "202",
			},
			{
				branch: "SABON TASHA KEFFI BRANCH",
				sol: "221",
			},
			{
				branch: "KARU BRANCH",
				sol: "285",
			},
		],
		region: "60j"
	},
];

export const countries = [
	{ country: "SYRIA", country_code: "SY", country_phone_code: "+963" },
	{ country: "Afghanistan", country_code: "AF", country_phone_code: "+93" },
	{ country: "Belarus", country_code: "BY", country_phone_code: "+375" },
	{
		country: "Democratic Republic of Congo",
		country_code: "CD",
		country_phone_code: "+242",
	},
	{ country: "Iran", country_code: "IR", country_phone_code: "+98" },
	{ country: "Burma", country_code: "MM", country_phone_code: "+95" },
	{ country: "LEBANON", country_code: "LB", country_phone_code: "+961" },
	{ country: "ANDORA", country_code: "AD", country_phone_code: "+376" },
	{
		country: "UNITED ARAB EMIRATES",
		country_code: "AE",
		country_phone_code: "+971",
	},
	{ country: "ANGOLA", country_code: "AO", country_phone_code: "+244" },
	{ country: "ARGENTINA", country_code: "AR", country_phone_code: "+54" },
	{ country: "AUSTRIA", country_code: "AT", country_phone_code: "+43" },
	{ country: "AUSTRALIA", country_code: "AU", country_phone_code: "+61" },
	{ country: "BELGIUM", country_code: "BE", country_phone_code: "+32" },
	{
		country: "BURKINA FASO",
		country_code: "BF",
		country_phone_code: "+226",
	},
	{ country: "BULGARIA", country_code: "BG", country_phone_code: "+359" },
	{ country: "BURUNDI", country_code: "BI", country_phone_code: "+257" },
	{
		country: "BENIN REPUBLIC",
		country_code: "BJ",
		country_phone_code: "+229",
	},
	{ country: "BRAZIL", country_code: "BR", country_phone_code: "+55" },
	{ country: "BOTSWANA", country_code: "BW", country_phone_code: "+267" },
	{ country: "CANADA", country_code: "CA", country_phone_code: "+1" },
	{
		country: "CENTRAL AFRICA REPUBLIC",
		country_code: "CF",
		country_phone_code: "+236",
	},
	{ country: "CONGO", country_code: "CG", country_phone_code: "+242" },
	{ country: "SWITZERLAND", country_code: "CH", country_phone_code: "+41" },
	{
		country: "COTE D'IVOIRE",
		country_code: "CI",
		country_phone_code: "+225",
	},
	{ country: "CHILE", country_code: "CL", country_phone_code: "+56" },
	{ country: "CAMEROON", country_code: "CM", country_phone_code: "+237" },
	{ country: "CHINA", country_code: "CN", country_phone_code: "+86" },
	{ country: "COLOMBIA", country_code: "CO", country_phone_code: "+57" },
	{ country: "COSTA RICA", country_code: "CR", country_phone_code: "+506" },
	{ country: "CUBA", country_code: "CU", country_phone_code: "+53" },
	{ country: "CAPE VERDE", country_code: "CV", country_phone_code: "+238" },
	{ country: "CYPRUS", country_code: "CY", country_phone_code: "+537" },
	{
		country: "CZECH REPUBLIC",
		country_code: "CZ",
		country_phone_code: "+420",
	},
	{ country: "GERMANY", country_code: "DE", country_phone_code: "+49" },
	{ country: "DENMARK", country_code: "DK", country_phone_code: "+45" },
	{ country: "ALGERIA", country_code: "DZ", country_phone_code: "+213" },
	{ country: "ECUADOR", country_code: "EC", country_phone_code: "+593" },
	{
		country: "ECOWAS COUNTRY CODE",
		country_code: "ECO",
		country_phone_code: "",
	},
	{
		country: "ECONOMIC COMMUNITY OF WEST AFRICAN STATES",
		country_code: "ECOWA",
	},
	{ country: "EGYPT", country_code: "EG", country_phone_code: "+20" },
	{ country: "SPAIN", country_code: "ES", country_phone_code: "+34" },
	{ country: "ETHIOPIA", country_code: "ET", country_phone_code: "+251" },
	{
		country: "EUROPEAN UNION COUNTRIES",
		country_code: "EU",
		country_phone_code: "",
	},
	{ country: "FINLAND", country_code: "FI", country_phone_code: "+358" },
	{ country: "FRANCE", country_code: "FR", country_phone_code: "+33" },
	{ country: "GABON", country_code: "GA", country_phone_code: "+241" },
	{
		country: "UNITED KINGDOM",
		country_code: "GB",
		country_phone_code: "+44",
	},
	{ country: "GEORGIA", country_code: "GE", country_phone_code: "+995" },
	{ country: "GHANA", country_code: "GH", country_phone_code: "+233" },
	{ country: "GAMBIA", country_code: "GM", country_phone_code: "+220" },
	{ country: "GUINEA", country_code: "GN", country_phone_code: "+224" },
	{
		country: "EQUITORIAL GUINEA",
		country_code: "GQ",
		country_phone_code: "+240",
	},
	{ country: "GREECE", country_code: "GR", country_phone_code: "+30" },
	{
		country: "GUINEA BISSAU",
		country_code: "GW",
		country_phone_code: "+245",
	},
	{ country: "HONG KONG", country_code: "HK", country_phone_code: "+852" },
	{ country: "HONDURAS", country_code: "HN", country_phone_code: "+504" },
	{ country: "CROATIA", country_code: "HR", country_phone_code: "+385" },
	{ country: "HAITI", country_code: "HT", country_phone_code: "+509" },
	{ country: "HUNGARY", country_code: "HU", country_phone_code: "+36" },
	{ country: "IDA", country_code: "IA", country_phone_code: "" },
	{ country: "IFC", country_code: "IC", country_phone_code: "" },
	{ country: "INDONESIA", country_code: "ID", country_phone_code: "+62" },
	{ country: "IRELAND", country_code: "IE", country_phone_code: "+353" },
	{ country: "ISRAEL", country_code: "IL", country_phone_code: "+972" },
	{ country: "INDIA", country_code: "IN", country_phone_code: "+246" },
	{ country: "IRAQ", country_code: "IQ", country_phone_code: "+964" },
	{ country: "ICELAND", country_code: "IS", country_phone_code: "+354" },
	{ country: "ITALY", country_code: "IT", country_phone_code: "+39" },
	{ country: "JAMAICA", country_code: "JM", country_phone_code: "+1876" },
	{ country: "JORDAN", country_code: "JQ", country_phone_code: "+962" },
	{ country: "JAPAN", country_code: "JP", country_phone_code: "+81" },
	{ country: "KENYA", country_code: "KE", country_phone_code: "+254" },
	{ country: "NORTH KOREA", country_code: "KP", country_phone_code: "+850" },
	{ country: "SOUTH KOREA", country_code: "KR", country_phone_code: "+82" },
	{ country: "LEBANON", country_code: "LE", country_phone_code: "+961" },
	{ country: "LIBERIA", country_code: "LR", country_phone_code: "+231" },
	{ country: "LESOTHO", country_code: "LS", country_phone_code: "+266" },
	{ country: "MOROCCO", country_code: "MA", country_phone_code: "+212" },
	{ country: "MADAGASCAR", country_code: "MG", country_phone_code: "+261" },
	{ country: "MALI", country_code: "ML", country_phone_code: "+223" },
	{ country: "MAURITANIA", country_code: "MR", country_phone_code: "+222" },
	{ country: "MAURITIUS", country_code: "MU", country_phone_code: "+230" },
	{ country: "MALAWI", country_code: "MW", country_phone_code: "+265" },
	{ country: "MEXICO", country_code: "MX", country_phone_code: "+52" },
	{ country: "MALAYSIA", country_code: "MY", country_phone_code: "+60" },
	{ country: "MOZAMBIQUE", country_code: "MZ", country_phone_code: "+258" },
	{ country: "NAMIBIA", country_code: "NA", country_phone_code: "+264" },
	{ country: "NIGER", country_code: "NE", country_phone_code: "+227" },
	{ country: "NIGERIA", country_code: "NG", country_phone_code: "+234" },
	{ country: "NETHERLANDS", country_code: "NL", country_phone_code: "+31" },
	{ country: "NORWAY", country_code: "NO", country_phone_code: "+47" },
	{ country: "NEPAL", country_code: "NP", country_phone_code: "+977" },
	{ country: "NEW ZEALAND", country_code: "NZ", country_phone_code: "+64" },
	{ country: "PERU", country_code: "PE", country_phone_code: "+51" },
	{ country: "PHILIPINES", country_code: "PH", country_phone_code: "+63" },
	{ country: "PAKISTAN", country_code: "PK", country_phone_code: "+92" },
	{ country: "POLAND", country_code: "PL", country_phone_code: "+48" },
	{
		country: "PUERTO RICO",
		country_code: "PR",
		country_phone_code: "+1787",
	},
	{ country: "PORTUGAL", country_code: "PT", country_phone_code: "+351" },
	{ country: "PARAGUAY", country_code: "PY", country_phone_code: "+595" },
	{ country: "ROMANIA", country_code: "RO", country_phone_code: "+40" },
	{ country: "RWANDA", country_code: "RW", country_phone_code: "+250" },
	{
		country: "SAUDI ARABIA",
		country_code: "SC",
		country_phone_code: "+966",
	},
	{ country: "SUDAN", country_code: "SD", country_phone_code: "+249" },
	{ country: "SWEDEN", country_code: "SE", country_phone_code: "+46" },
	{ country: "SINGAPORE", country_code: "SG", country_phone_code: "+65" },
	{ country: "SLOVENIA", country_code: "SI", country_phone_code: "+386" },
	{
		country: "SIERRA LEONE",
		country_code: "SL",
		country_phone_code: "+232",
	},
	{ country: "SENEGAL", country_code: "SN", country_phone_code: "+221" },
	{ country: "SOMALIA", country_code: "SO", country_phone_code: "+252" },
	{ country: "SWAZILAND", country_code: "SZ", country_phone_code: "+268" },
	{ country: "CHAD", country_code: "TD", country_phone_code: "+235" },
	{ country: "TOGO", country_code: "TG", country_phone_code: "+228" },
	{ country: "THAILAND", country_code: "TH", country_phone_code: "+66" },
	{ country: "TUNISIA", country_code: "TN", country_phone_code: "+216" },
	{ country: "TURKEY", country_code: "TR", country_phone_code: "+90" },
	{ country: "TANZANIA", country_code: "TZ", country_phone_code: "+255" },
	{ country: "UKRAINE", country_code: "UA", country_phone_code: "+380" },
	{ country: "UGANDA", country_code: "UG", country_phone_code: "+256" },
	{
		country: "UNITED STATE OF AMERICA",
		country_code: "US",
		country_phone_code: "+1",
	},
	{ country: "URUGUAY", country_code: "UY", country_phone_code: "+598" },
	{ country: "VENEZUELA", country_code: "VE", country_phone_code: "+58" },
	{ country: "YUGOSLAVIA", country_code: "YU", country_phone_code: "+38" },
	{ country: "SOUTH AFRICA", country_code: "ZA", country_phone_code: "+27" },
	{ country: "ZAMBIA", country_code: "ZM", country_phone_code: "+260" },
	{ country: "ZIMBABWE", country_code: "ZW", country_phone_code: "+263" },
	{ country: "ALBANIA", country_code: "AL", country_phone_code: "+355" },
	{ country: "ARMENIA", country_code: "AM", country_phone_code: "+374" },
	{
		country: "BOSNIA AND HERZEGOVINA",
		country_code: "BA",
		country_phone_code: "+387",
	},
	{ country: "BAHRAIN", country_code: "BH", country_phone_code: "+973" },
	{
		country: "SAINT BARTHELEMY",
		country_code: "BL",
		country_phone_code: "+590",
	},
	{ country: "BHUTAN", country_code: "BT", country_phone_code: "+975" },
	{ country: "BOUVET ISLAND", country_code: "BV", country_phone_code: "" },
	{
		country: "COOK ISLANDS",
		country_code: "CK",
		country_phone_code: "+682",
	},
	{ country: "CURACAO", country_code: "CW", country_phone_code: "+599" },
	{ country: "DJIBOUTI", country_code: "DJ", country_phone_code: "+253" },
	{
		country: "DOMINICAN REPUBLIC",
		country_code: "DO",
		country_phone_code: "+1809",
	},
	{ country: "ESTONIA", country_code: "EE", country_phone_code: "+372" },
	{
		country: "WESTERN SAHARA",
		country_code: "EH",
		country_phone_code: "+212",
	},
	{ country: "ERITREA", country_code: "ER", country_phone_code: "+291" },
	{ country: "FIJI", country_code: "FJ", country_phone_code: "+679" },
	{
		country: "FALKLAND ISLANDS (MALVINAS)",
		country_code: "FK",
		country_phone_code: "+500",
	},
	{
		country: "FAROE ISLANDS",
		country_code: "FO",
		country_phone_code: "+298",
	},
	{
		country: "FRENCH GUIANA",
		country_code: "GF",
		country_phone_code: "+594",
	},
	{ country: "GIBRALTAR", country_code: "GI", country_phone_code: "+350" },
	{ country: "GREENLAND", country_code: "GL", country_phone_code: "+299" },
	{ country: "GUATEMALA", country_code: "GT", country_phone_code: "+502" },
	{ country: "JERSEY", country_code: "JE", country_phone_code: "+44" },
	{ country: "KYRGYZSTAN", country_code: "KG", country_phone_code: "+996" },
	{
		country: "SAINT KITTS AND NEVIS",
		country_code: "KN",
		country_phone_code: "+1",
	},
	{ country: "KUWAIT", country_code: "KW", country_phone_code: "+965" },
	{ country: "MONACO", country_code: "MC", country_phone_code: "+377" },
	{
		country: "SAINT MARTIN (FRENCH PART)",
		country_code: "MF",
		country_phone_code: "+590",
	},
	{ country: "MALTA", country_code: "MT", country_phone_code: "+356" },
	{ country: "OMAN", country_code: "ON", country_phone_code: "+968" },
	{
		country: "FRENCH POLYNESIA",
		country_code: "PF",
		country_phone_code: "+689",
	},
	{
		country: "SAINT PIERRE AND MIQUELON",
		country_code: "PM",
		country_phone_code: "+508",
	},
	{ country: "QATAR", country_code: "QA", country_phone_code: "+974" },
	{ country: "SERBIA", country_code: "RS", country_phone_code: "+381" },
	{
		country: "SAO TOME AND PRINCIPE",
		country_code: "ST",
		country_phone_code: "+239",
	},
	{
		country: "SINT MAARTEN (DUTCH PART)",
		country_code: "SX",
		country_phone_code: "+721",
	},
	{
		country: "TURKS AND CAICOS ISLANDS",
		country_code: "TC",
		country_phone_code: "+1649",
	},
	{
		country: "FRENCH SOUTHERN TERRITORIES",
		country_code: "TF",
		country_phone_code: "",
	},
	{ country: "TONGA", country_code: "TO", country_phone_code: "+676" },
	{ country: "TUVALU", country_code: "TV", country_phone_code: "+688" },
	{
		country: "SAINT VINCENT AND THE GRENADINES",
		country_code: "VC",
		country_phone_code: "+1",
	},
	{
		country: "VIRGIN ISLANDS (BRITISH)",
		country_code: "VG",
		country_phone_code: "+1340",
	},
	{
		country: "WALLIS AND FUTUNA",
		country_code: "WF",
		country_phone_code: "+681",
	},
	{ country: "YEMEN", country_code: "YE", country_phone_code: "+967" },
	{ country: "BELIZE", country_code: "BZ", country_phone_code: "+501" },
	{
		country: "ALAND ISLANDS",
		country_code: "AX",
		country_phone_code: "+358",
	},
	{ country: "BANGLADESH", country_code: "BD", country_phone_code: "+880" },
	{
		country: "BOLIVIA (PLURINATIONAL STATE OF)",
		country_code: "BO",
		country_phone_code: "+591",
	},
	{ country: "GUADELOUPE", country_code: "GP", country_phone_code: "+590" },
	{
		country: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
		country_code: "GS",
		country_phone_code: "+500",
	},
	{ country: "GUAM", country_code: "GU", country_phone_code: "+1671" },
	{ country: "GUYANA", country_code: "GY", country_phone_code: "+595" },
	{
		country: "HEARD ISLAND AND MCDONALD ISLANDS",
		country_code: "HM",
		country_phone_code: "",
	},
	{ country: "DOMINICA", country_code: "DM", country_phone_code: "+1767" },
	{
		country: "MICRONESIA (FEDERATED STATES OF)",
		country_code: "FM",
		country_phone_code: "+691",
	},
	{ country: "GRENADA", country_code: "GD", country_phone_code: "+1473" },
	{ country: "ISLE OF MAN", country_code: "IM", country_phone_code: "+44" },
	{
		country: "BRITISH INDIAN OCEAN TERRITORY",
		country_code: "IO",
		country_phone_code: "+246",
	},
	{ country: "CAMBODIA", country_code: "KH", country_phone_code: "+855" },
	{ country: "KIRIBATI", country_code: "KI", country_phone_code: "+686" },
	{ country: "COMOROS", country_code: "KM", country_phone_code: "+269" },
	{
		country: "CAYMAN ISLANDS",
		country_code: "KY",
		country_phone_code: "+345",
	},
	{
		country: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
		country_code: "LA",
		country_phone_code: "+856",
	},
	{ country: "SAINT LUCIA", country_code: "LC", country_phone_code: "+1" },
	{
		country: "LIECHTENSTEIN",
		country_code: "LI",
		country_phone_code: "+423",
	},
	{ country: "SRI LANKA", country_code: "LK", country_phone_code: "+94" },
	{ country: "LITHUANIA", country_code: "LT", country_phone_code: "+370" },
	{ country: "LUXEMBOURG", country_code: "LU", country_phone_code: "+352" },
	{ country: "LATVIA", country_code: "LV", country_phone_code: "+371" },
	{
		country: "MOLDOVA (REPUBLIC OF)",
		country_code: "MD",
		country_phone_code: "+373",
	},
	{ country: "MONTENEGRO", country_code: "ME", country_phone_code: "+382" },
	{
		country: "MARSHALL ISLANDS",
		country_code: "MH",
		country_phone_code: "+692",
	},
	{ country: "NOT APPLICABLE", country_code: "N/A", country_phone_code: "" },
];

export const marital_status = [
	{ label: "DIVORCEE", value: "DIV" },
	{ label: "LEGALLY SEPARATED", value: "LSP" },
	{ label: "MARRIED", value: "MAR" },
	{ label: "SINGLE", value: "SNG" },
	{ label: "UNMARRIED", value: "UMR" },
	{ label: "WIDOW", value: "WID" },
	{ label: "WIDOWER", value: "WDW" },
	{ label: "OTHERS", value: "OTHR" },
];

export const employment_status = [
	{ label: "EMPLOYED", value: "Employed" },
	{ label: "HOUSEWIFE", value: "Housewife" },
	{ label: "RETIRED", value: "Retired" },
	{ label: "SALARIED", value: "Salaried" },
	{ label: "SELF EMPLOYED", value: "Self employed" },
	{ label: "UNEMPLOYED", value: "Unemployed" },
	{ label: "OTHERS", value: "Other" },
];

export const filesDetails = [
	{
		label: "Completed Account Package",
		formLabel: "completedAccountPackage",
		value: "CAP",
		show: false,
	},
	{
		label: "CTC of Form CO7",
		formLabel: "formOfCO7",
		value: "CO7",
		show: true,
	},
	{
		label: "CTC of Form CO2",
		formLabel: "formOfCAC23",
		value: "CO2",
		show: true,
	},
	{
		label: "Certificate of Incorporation",
		formLabel: "certificateOfIncorporation",
		value: "COP",
		show: true,
	},
	{
		label: "CTC of Memo and Articles of Association",
		formLabel: "MemorandumAndArticleOfAssociation",
		value: "CTC of MAA",
		show: true,
	},
	{
		label: "Tax Identification Number",
		formLabel: "taxIdentificationNumber",
		value: "TIN",
		show: false,
	},
	{
		label: "Valid ID of all Signatories",
		formLabel: "validIdOfAllSignatories",
		value: "VALID ID",
		show: false,
	},
	{
		label: "SIGNATURE CARDS",
		formLabel: "signatureOfSignatories",
		value: "SIGNATURE CARDS",
		show: true,
	},
	{
		label: "Valid ID of Directors",
		formLabel: "validIdOfDirectors",
		value: "VALID DIREC",
		show: false,
	},
	{
		label: "BVN of Signatories",
		formLabel: "BVNOfSignatories",
		value: "BVNSIG",
		show: false,
	},
	{
		label: "BVN of Directors",
		formLabel: "BVNOfDirectors",
		value: "BVNDIREC",
		show: false,
	},
	{
		label: "Residence Permit/Card Expatriates Signatories",
		formLabel: "RP/ResidencePermitCardExpatriatesSignatories",
		value: "RP/CES",
		show: true,
	},
	{
		label: "Passport Photographs of all signatories",
		formLabel: "PassportPhotographsOfAllSignatories",
		value: "PPS",
		show: false,
	},
	{
		label: "Executed and sealed Board Resolution",
		formLabel: "boardResolution",
		value: "EBR",
		show: true,
	},
	{
		label: "Reference 1",
		formLabel: "firstReferenceForCompanies",
		value: "REF1",
		show: true,
	},
	{
		label: "Reference 2",
		formLabel: "secondReferenceForCompanies",
		value: "REF2",
		show: true,
	},
	{
		label: "Scuml",
		formLabel: "scumlCertificate",
		value: "SCUML",
		show: true,
	},
	{
		label: "Certificate of Compliance with NEPB",
		formLabel: "CertificateOfComplianceWithNEPB",
		value: "CCCNE",
		show: true,
	},
	{
		label: "Recent Utility Bill(one in the last 3 months)",
		formLabel: "RecentUtilityBill",
		value: "Utility bill",
		show: true,
	},
	{
		label: "Address verification",
		formLabel: "AVR",
		value: "AVR",
		show: true,
	},
	{
		label: "Legal search report",
		formLabel: "LegalSearchReport",
		value: "SR",
		show: false,
	},
	{
		label: "Form of Application for Registration of Business Name",
		formLabel: "FormOfApplicationForRegistrationOfBusinessName",
		value: "FARBN",
		show: true,
	},
	{
		label: "Letter of appointment as bankers for Enterprise",
		formLabel: "LetterOfAppointmentAsBankersForEnterprise",
		value: "LAB",
		show: true,
	},
	{
		label: "Constitutions,Rules,Regulations and By-laws",
		formLabel: "constitutionRulesAndRegulations",
		value: "CRRB",
		show: true,
	},
	{
		label: "Certificate of Registration of Business name",
		formLabel: "certificateOfRegistration",
		value: "CRB",
		show: true,
	},
];

export const timeout = 1000 * 60 * 10;
export const debounce = 500;

export const timeoutDropOffObj = {
	dropOffStatus: true,
	dropOffReason: DropOffReason.Timeout,
};
export const nullDropOffObject = {
	dropOffStatus: false,
	dropOffReason: null,
	dropOffStep: null,
};

export const domicilaryAccounts = [
	{
		currencyType: "USD",
		correspondentBank:
			"CITIBANK, New York, USA 399 Park Avenue, New York, NY10043. U.S.A.",
		cSwiftCode: "CITIUS33XXX",
		routingNo: "021000089",
		beneficiaryAccount: "First City Monument Bank Plc",
		bSwiftCode: "FCMBNGLAXXX",
		bAccountNumber: "36887918",
	},
	{
		currencyType: "GBP",
		correspondentBank:
			"CITIBANK, London, UK Citigroup Centre, Canada Square, Canary Wharf, London E14 5LB",
		cSwiftCode: "CITIGB2LXXX",
		sortCode: "185008",
		beneficiaryAccount: "First City Monument Bank Plc",
		bSwiftCode: "FCMBNGLAXXX",
		bAccountNumber: "11871706",
		ibanNo: "GB08CITI18500811871706",
	},
	{
		currencyType: "EUR",
		correspondentBank:
			"CITIBANK, London, UK Citigroup Centre, Canada Square, Canary Wharf, London E14 5LB",
		cSwiftCode: "CITIGB2LXXX",
		sortCode: "185008",
		beneficiaryAccount: "First City Monument Bank Plc",
		bSwiftCode: "FCMBNGLAXXX",
		bAccountNumber: "11871684",
		ibanNo: "GB20CITI18500811871684",
	},
];

export const legalEntities = [
	{
			value: 'CMB',
			label: 'COMMUNITY BANK',
	},
	{
			value: 'COR',
			label: 'CORPORATE CUSTOMER',
	},
	{
			value: 'FCOY',
			label: 'FINANCE COMPANY',
	},
	{
			value: 'INS',
			label: 'INSURANCE COMPANY',
	},
	{
			value: 'LOCG',
			label: 'LOCAL GOVERNMENT',
	},
	{
			value: 'MTGI',
			label: 'MORTGAGE INSTITUTION',
	},
	{
			value: 'NFI',
			label: 'NON BANK FINANCIAL INSTITUTION',
	},
	{
			value: 'OTCY',
			label: 'OTHER COMPANY',
	},
	{
			value: 'PAR',
			label: 'PARTNERSHIP',
	},
	{
			value: 'PRIV',
			label: 'PRIVATE COMPANY',
	},
	{
			value: 'STGV',
			label: 'STATE GOVERNMENT',
	},
	{
			value: 'COMB',
			label: 'COMMERCIAL BANK',
	},
	{
			value: 'FGVT',
			label: 'FEDERAL GOVERNMENT',
	},
	{
			value: 'MFBK',
			label: 'MICRO-FINANCE BANK',
	},
	{
			value: 'NFPE',
			label: 'NON FINANCIAL PUBLIC ENTERPRISE',
	},
	{
			value: 'NPRI',
			label: 'NON PROFIT INSTITUTION',
	},
	{
			value: 'NONCI',
			label: 'NONCITIZEN ACCOUNT TYPE',
	},
	{
			value: 'PARA',
			label: 'PARASTATAL',
	},
	{
			value: 'PPAR',
			label: 'POLITICAL PARTY',
	},
	{
			value: 'PLCS',
			label: 'PUBLIC LIMITED COMPANY',
	},
	{
			value: 'RSTA',
			label: 'RELATIVE OF BANK STAFF',
	},
	{
			value: 'SHGS',
			label: 'SELF HELP GROUP',
	},
	{
			value: 'SOLE',
			label: 'SOLE PROPRIETOR',
	},
	{
			value: 'STAT',
			label: 'STATUTORY BODY',
	},
	{
			value: 'TRUS',
			label: 'TRUSTS',
	},
	{
			value: 'WFP',
			label: 'WORLD FOOD PROGRAM',
	},
	{
			value: 'AGRIC',
			label: 'AGRICULTURE',
	},
	{
			value: 'AVIA',
			label: 'AVIATION',
	},
	{
			value: 'BNK',
			label: 'BANKS',
	},
	{
			value: 'CLU',
			label: 'CLUBS AND ASSOCIATIONS',
	},
	{
			value: 'DICH',
			label: 'DISCOUNT HOUSE',
	},
	{
			value: 'EDU',
			label: 'EDUCATIONAL INSTITUTIONS',
	},
	{
			value: 'FIN',
			label: 'FINANCIAL INSTITUTIONS',
	},
	{
			value: 'GOV',
			label: 'GOVERNMENT',
	},
	{
			value: 'HEA',
			label: 'HEALTH INSTITUTION',
	},
	{
			value: 'INFO',
			label: 'INFORMATION TECHNOLOGY',
	},
	{
			value: 'INO',
			label: 'INTERNATIONAL ORGANIZATIONS',
	},
	{
			value: 'MEDIA',
			label: 'MEDIA/PRESS',
	},
	{
			value: 'MIL',
			label: 'MILITARY/ARMED FORCES',
	},
	{
			value: 'MIN',
			label: 'MINISTRY/PARASTAL',
	},
	{
			value: 'NGO',
			label: 'NON-GOVERNMENTAL ORGANISATION',
	},
	{
			value: 'EST',
			label: 'REAL ESTATE',
	},
	{
			value: 'REL',
			label: 'RELIGIOUS ORGANIZATIONS',
	},
	{
			value: 'TEL',
			label: 'TELECOMMUNICATION',
	},
	{
			value: 'UNC',
			label: 'UNINCORPORATED',
	},
	{
			value: 'GAMMING',
			label: 'GAMMING',
	},
	{
			value: 'MONEY TRANSFER OPERATORS',
			label: 'MONEY TRANSFER OPERATORS',
	},
	{
			value: 'IMPORTER AND EXPORTER',
			label: 'IMPORTER AND EXPORTER',
	},
	{
			value: 'UNLICENSED BDC',
			label: 'UNLICENSED BDC',
	},
];
