import Axios from "../../config/Axios";
import { notifySuccess } from "../../utils/helpers";

export const generateEmailOtp = async (email: string) => {
	try {
		let response = await Axios.post(`generate-email-otp`, {
			email,
			message: "",
		});
		if (response.status === 200 && response.data?.code === "00") {
			return response.data.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const validateEmailOtp = async (payload: any) => {
	try {
		let response = await Axios.post(`validate-email-otp`, payload);
		if (response.status === 200 && response.data?.code === "00") {
			return true;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

// generate account otp
export const generateAccountOTP = async (accountNo: string) => {
	try {
		let response = await Axios.post(`generate-account-otp`, {
			accountNo,
			channelcode: "Mobile",
			message: "",
		});
		return response.data.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// validate account otp
export const validateAccountOTP = async (payload: {}) => {
	try {
		let response = await Axios.post(`validate-account-otp`, payload);
		if (response.status === 200 && response.data?.code === "00") {
			notifySuccess(response.data.description);
			return response.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};
