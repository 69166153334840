export enum Products {
	FcmbBusinessAccount = "FCMB Business Account",
	CorporateCurrentAccount = "Corporate Current Account",
}

export enum BusinessCategory {
	SoleProprietorship = "Sole Proprietorship",
	LimitedLiabilityCompany = "Limited Liability Company",
	NGOAssociationCooperative = "NGO, Association & Cooperative",
}

export enum NewAccountSteps {
	requirements = "requirements",
	otp = "otp",
	companyInfo = "company-info",
	productInfo = "product-info",
	companyDetails = "company-details",
	directorsDetails = "directors-details",
	signatoriesDetails = "signatories-details",
	bankingServices = "banking-services",
	accountMandate = "account-mandate",
	accountMandateConfirmation = "account-mandate-confirmation",
	referenceDetails = "reference-details",
	documents = "documents",
	review = "review",
	completed = "completed",
}

export enum DropOffReason {
	Timeout = "Timeout",
}

export enum AdditionalAccountSteps {
	accountInfo = "account-info",
	otp = "otp",
	status = "status",
	productInfo = "product-info",
	bankingServices = "banking-services",
	documents = "documents",
	documentValues = "document-values",
	completed = "completed",
}

export enum ReferenceSteps {
	accountInfo = "accountInfo",
	otp = "otp",
	referenceType = "referenceType",
	ticket = "ticket",
	sendReference = "sendReference",
}

export enum AccountReactivationSteps {
	accountInfo = "account-info",
	otp = "otp",
	accountStatus = "account-status",
	companyDetails = "company-details",
	boardResolution = "board-resolution",
	documents = "documents",
	documentValues = "document-values",
	bankingServices = "banking-services",
	completed = "completed",
}

export enum RoutesEnum {
	accountOpening = "account-opening",
	accountReactivation = "account-reactivation",
	additionalAccount = "additional-account",
	businessRegistration = "business-registration",
	accountStatus = "account-status",
	references = "references",
	digitalReference = "digital-reference",
	contact = "contact",
	documentUpload = "document-upload",
}

export enum BusinessRegistrationSteps {
	availableServices = "available-services",
	accountDetails = "account-details",
	identityVerification = "identity-verification",
	otp = "otp",
	ownerInformation = "owner-information",
	businessInformation = "business-information",
	businessName = "business-name",
	shareholderDetails = "shareholder-details",
	presenterDetails = "presenter-details",
	servicePaymentConfirmation = "service-payment-confirmation",
	servicePayment = "service-payment",
	completed = "completed",
	preview= "preview"
}

export enum ShareholderTypeEnum  {
	Individual = "Individual", Corporate = "Corporate"
}

export enum TruthyEnum  {
	Yes = "Yes", No = "No"
}

export enum AccountStatusSteps {
	accountDetails = "account-details",
	otp = "otp",
	status = "status",
}