import Axios from "../../config/Axios";

export {
	getProducts,
	verifyId,
	verifyBVN,
	createCoperateAccount,
	createCoperateMembers,
	getVerifiedUserLive,
	verifyBusinessAddress,
	addMandate,
	createAdditionalAccount,
	getBusinessRequests,
	sendBusinessRequests,
	sendServiceRequests,
	getServiceRequests,
	reactivateAccount,
	getAccountStatus,
	sendFcmbReference,
	sendOtherBankReference,
	updateOtherBankReference,
	verifyRefreeAccount,
	checkRcOnFinacle,
} from "./accounts";

export { generateEmailOtp, validateEmailOtp, generateAccountOTP, validateAccountOTP } from "./otp";
export { getAccountDetails, getOutStandingDocs, updateDocument, uploadDocument } from "./documentUpload";
export {
	newAccountSaveAndContinueAction,
	getNewAccountSavedData,
	additionalAccountSaveAndContinueAction,
	getAdditionalAccountSavedData,
	getAccountReactivationSavedData,
	accountReactivationSaveAndContinueAction,
	businessRegistrationSaveAndContinueAction,
	getBusinessRegistrationSavedData,
} from "./saveAndContinue";

export {assignRegisterMyBusinessToConsultant} from './businessRegistration'

export const clickjackingFix = async () => {
	try {
		let response = await Axios.get(`OpenXframe`);
		if (response.status === 200) {
			return response.data
		}
	} catch (error) {
	}
};
