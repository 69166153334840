/* eslint-disable no-throw-literal */

import axios from "axios";
import config from "./config";
import store from "../store";
import { uiStartLoading, uiStopLoading } from "../store/actions/loading";
import { generateHeader } from "../utils/helpers";
const SaveAndContinueAxios = axios.create({
  baseURL: config.SAVE_CONTINUE_SERVER,
  headers: {
    "Cross-Origin-Opener-Policy": "same-origin",
    "X-Frame-Options": "SAMEORIGIN",
    "X-Content-Type-Options": "nosniff",
  },
});

const { dispatch } = store;

SaveAndContinueAxios.interceptors.request.use(
  (axiosConfig) => {
		if (!navigator.onLine) {
			throw new Error("Please check your Internet Connection")
		}
    // if (config.IS_CLOUD) {
		// 	const headers = generateHeader();
		// 	axiosConfig.headers['x-token'] = headers['x-token'];
		// 	axiosConfig.headers['Ocp-Apim-Subscription-Key'] = headers['Ocp-Apim-Subscription-Key'];
		// 	axiosConfig.headers['Ocp-Apim-Trace'] = true;
		// 	axiosConfig.headers.UTCTimestamp = headers.UTCTimestamp;
		// 	axiosConfig.headers.Client_ID = headers.Client_ID;
		// }
    dispatch(uiStartLoading());
    return axiosConfig;
  },
  (error) => {
    dispatch(uiStopLoading());
    return Promise.reject(error);
  }
);

SaveAndContinueAxios.interceptors.response.use(
  (response) => {
    if (response.status !== 200 && response.data?.code !== "00") {
      dispatch(uiStopLoading());
      throw new Error(response?.data?.description)
    }
    dispatch(uiStopLoading());
    return response;
  },
  async (err) => {
    dispatch(uiStopLoading());
    return Promise.reject(err);
  }
);

export default SaveAndContinueAxios;
